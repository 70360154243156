import { AppState, useSelector } from "@/store/store/Store";
import { useMediaQuery, Box, Container, Theme } from "@mui/material";

import DesktopSecondaryNavList from "../NavLists/DesktopSecondaryNavList/DesktopSecondaryNavList";
import { MenuType } from "@/store/types/coreTypes";

interface DesktopNavigationProps {
  groupMenu: string;
}

const DesktopNavigation: React.FC<DesktopNavigationProps> = ({ groupMenu }) => {
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));

  const menu = useSelector((state: AppState) => state.user.menu);
  const menuItems = menu.find((item: MenuType) => item.name === groupMenu);
  const menuItem = menuItems?.children ?? [];

  if (lgUp) {
    return (
      <Box pt={2}>
        <Container maxWidth="lg">
          {menuItem.length > 0 && <DesktopSecondaryNavList items={menuItem} />}
        </Container>
      </Box>
    );
  } else {
    return;
  }
};

export default DesktopNavigation;
