import React from "react";
import {
  Typography,
  Modal,
  Box,
  Container,
  Grid,
  Button,
  useTheme,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import ErrorIcon from "@mui/icons-material/Error";

type FullModalType = {
  button?: boolean;
  open: boolean;
  handleClose: () => void;
  handleTryAgain?: () => void;
  message: String;
  type: "SUCCESS" | "ERROR" | "OPS" | "NONE" | "INSTA";
};

const AlertModal: React.FC<FullModalType> = ({
  button,
  open,
  handleClose,
  handleTryAgain,
  message,
  type,
}) => {
  const theme = useTheme();
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        style={{
          // overflow: "-moz-scrollbars-vertical",
          // overflowY: "scroll",
          zIndex: "10000000000000",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // backgroundColor: type == "OPS" ? "red" : "transparent",
          }}
        >
          <Container disableGutters maxWidth="xs">
            <Box style={{ padding: "10px" }}>
              <Box
                style={{
                  backgroundColor: theme.palette.white.main,
                  borderRadius: "30px",
                }}
              >
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  padding={2}
                >
                  <Grid item alignSelf="flex-end">
                    <Button
                      aria-label="Example"
                      color="white"
                      variant="contained"
                      style={{
                        borderRadius: "40px",
                        width: "40px",
                        height: "40px",
                        minWidth: "unset",
                        boxShadow: "unset",
                      }}
                      onClick={handleClose}
                    >
                      <CloseOutlinedIcon fontSize="large" color="action" />
                    </Button>
                  </Grid>
                  <Grid item>
                    {type == "SUCCESS" && (
                      <CheckCircleIcon
                        style={{
                          width: "120px",
                          height: "120px",
                          color: theme.palette.success.main,
                        }}
                      />
                    )}
                    {type == "ERROR" && (
                      <CancelIcon
                        style={{
                          width: "120px",
                          height: "120px",
                          color: theme.palette.error.main,
                        }}
                      />
                    )}

                    {(type == "OPS" || type == "INSTA") && (
                      <ErrorIcon
                        style={{
                          width: "120px",
                          height: "120px",
                          color: theme.palette.warning.main,
                        }}
                      />
                    )}
                  </Grid>
                  <Grid item style={{ marginTop: "29px" }}>
                    <Typography
                      variant="body1"
                      fontSize={"1rem"}
                      style={{
                        color:
                          type == "SUCCESS"
                            ? theme.palette.success.main
                            : type == "ERROR"
                            ? theme.palette.error.main
                            : theme.palette.warning.main,
                      }}
                    >
                      <strong>
                        {type == "SUCCESS"
                          ? "Sucesso!"
                          : type == "ERROR"
                          ? "Ops!"
                          : type == "INSTA"
                          ? "Atenção"
                          : "Ops!"}
                      </strong>
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    style={{
                      marginTop: "12px",
                      maxWidth: "230px",
                      marginBottom: "30px",
                    }}
                  >
                    <Typography
                      variant="body1"
                      fontSize={"1rem"}
                      component="p"
                      textAlign={"center"}
                    >
                      {message}
                    </Typography>
                  </Grid>
                  {button && type == "OPS" && (
                    <Grid item style={{ marginBottom: "30px" }}>
                      <Button
                        variant="contained"
                        color="terciary"
                        onClick={handleTryAgain}
                      >
                        Tente Novamente
                      </Button>
                    </Grid>
                  )}
                  {type == "INSTA" && (
                    <Grid item style={{ marginBottom: "30px" }}>
                      <Button
                        href={"/"}
                        target="_blank"
                        download
                        variant="contained"
                        color="terciary"
                      >
                        Abrir em um Browser
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Box>
          </Container>
        </Box>
      </Modal>
    </>
  );
};

export default AlertModal;
