export const capitalizedWords = (segment: string) => {
  const words = segment.split("-");

  const capitalizedWords = words.map((word, index) => {
    if (index === 0) {
      // Capitaliza a primeira letra da primeira palavra
      return word.charAt(0).toUpperCase() + word.slice(1);
    } else {
      // Mantém as outras palavras em minúsculas
      return word.toLowerCase();
    }
  });
  return capitalizedWords.join(" ");
};
