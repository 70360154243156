import HeaderLinks from "@/sections/_Layout/Header/HeaderLinks";
import { Box, Container, styled } from "@mui/material";

interface BlankLayoutProps {
  children: React.ReactNode;
}

const ContainerStyled = styled("div")(() => ({
  display: "flex",
  minHeight: "100vh",
  width: "100%",
  flexDirection: "column",
}));

const ChildrenStyled = styled(Box)(({ theme }) => ({
  flex: "1",
}));

const LinksLayout = ({ children }: BlankLayoutProps) => {
  return (
    <>
      <ContainerStyled>
        <HeaderLinks />
        <ChildrenStyled>
          <Container maxWidth="sm">{children}</Container>
        </ChildrenStyled>
      </ContainerStyled>
    </>
  );
};

export default LinksLayout;
