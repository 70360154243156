import React from "react";
import Link from "next/link";

// mui imports
import {
  ListItemIcon,
  ListItem,
  List,
  styled,
  ListItemText,
  useTheme,
} from "@mui/material";
import { AppState, useSelector } from "@/store/store/Store";
import { GetIconNew } from "@/utils/GetIcon/GetIconNew";

type NavGroup = {
  [x: string]: any;
  id?: string;
  navlabel?: boolean;
  subheader?: string;
  name?: string;
  icon?: any;
  href?: string;
  children?: NavGroup[];
  chip?: string;
  chipColor?: any;
  variant?: string | any;
  external?: boolean;
  level?: number;
};

interface ItemType {
  item: NavGroup;
  onClick?: React.MouseEventHandler<HTMLElement>;
  level?: number | any;
  pathDirect: string;
}

const NavItem = ({ item, level, pathDirect, onClick }: ItemType) => {
  const customizer = useSelector((state: AppState) => state.customizer);
  const Icon: any = GetIconNew(item.icon, { fontSize: "1.1rem !important" });
  const theme = useTheme();

  const ListItemStyled2 = styled(ListItem)(() => ({
    padding: "5px 10px",
    gap: "5px",
    borderRadius: `${customizer.borderRadius}px`,
    marginBottom: level > 1 ? "3px" : "0px",
    color:
      level > 1 && pathDirect === item.href
        ? `${theme.palette.primary.main}!important`
        : theme.palette.text.secondary,

    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
    "&.Mui-selected": {
      color: level > 1 ? theme.palette.primary.main : "black!important",
      backgroundColor: level > 1 ? "transparent" : theme.palette.primary.light,
      "&:hover": {
        backgroundColor: level > 1 ? "" : theme.palette.primary.main,
        color: "white",
      },
    },
  }));

  const listItemProps: {
    component: any;
    href?: string;
    target?: any;
    to?: any;
  } = {
    component: item?.external ? "a" : Link,
    to: item?.href,
    href: item?.href,
    target: item?.external ? "_blank" : "",
  };

  return (
    <List component="li" disablePadding key={item.id}>
      <ListItemStyled2
        {...listItemProps}
        disabled={item.disabled}
        selected={pathDirect === item.href}
        onClick={onClick}
      >
        <ListItemIcon
          sx={{
            minWidth: "auto",
            p: "3px 0",
            color: "inherit",
          }}
        >
          {Icon}
        </ListItemIcon>
        <ListItemText>{item.name}</ListItemText>
      </ListItemStyled2>
    </List>
  );
};

export default NavItem;
