import { useRouter } from "next/router";
import { Box, List } from "@mui/material";
import NavItem from "../NavItem";
import NavCollapse from "../NavCollapse";
import NavMenu from "../NavMenu";

interface DesktopPlayNavigationProps {
  items: any[];
}

const DesktopSecondaryNavList = ({ items }: DesktopPlayNavigationProps) => {
  const { pathname, query } = useRouter();
  const pathDirect = pathname;
  const pathWithoutLastPart = pathname.slice(0, pathname.lastIndexOf("/"));

  const hasSlug = Boolean(query.slug);
  const pathWithoutSlug =
    hasSlug && pathname.replace(/\/\[slug\](\/|$)/, (match, p1) => p1);

  const fullPathWithSlug = hasSlug
    ? `${pathWithoutSlug}/${query.slug}`
    : pathDirect;

  return (
    <Box>
      <List
        sx={{
          p: 0,
          display: "flex",
          gap: "8px",
          zIndex: "100",
        }}
      >
        {items.map((item: any, index: number) => {
          if (item.children && item.children.length > 1) {
            return (
              <NavCollapse
                menu={item}
                pathDirect={fullPathWithSlug}
                pathWithoutLastPart={pathWithoutLastPart}
                level={1}
                key={index}
              />
            );
          } else if (item.children && item.children.length == 1) {
            return <NavMenu item={item.children[0]} key={index} />;
          } else {
            return (
              <NavItem item={item} key={index} pathDirect={fullPathWithSlug} />
            );
          }
        })}
      </List>
    </Box>
  );
};
export default DesktopSecondaryNavList;
