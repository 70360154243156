import MobileBlankNavigation from "@/components/navigation/MobileNavigation/MobileBlankNavigation";
import MobileNavigation from "@/components/navigation/MobileNavigation/MobileAdminNavigation";
import Footer from "@/sections/_Layout/Footer/Footer";
import Header from "@/sections/_Layout/Header/Header";
import Newsletter from "@/sections/_Layout/Newsletter/Newsletter";
import { styled, Box } from "@mui/material";
import { MenuType } from "@/store/types/coreTypes";
import { useEffect } from "react";
import { dispatch } from "@/store/store/Store";
import { getMenu } from "@/store/reducers/CoreSlice";
import { getLastPosts } from "@/store/reducers/BlogSlice";
import { PostType } from "@/store/types/blogTypes";

const ContainerStyled = styled("div")(() => ({
  display: "flex",
  minHeight: "100vh",
  width: "100%",
  flexDirection: "column",
}));

const ChildrenStyled = styled(Box)(({ theme }) => ({
  flex: "1",
}));

interface Props {
  children: React.ReactNode;
  menu: MenuType[];
  posts: PostType;
}

const Layout: React.FC<Props> = ({ children, menu, posts }) => {
  useEffect(() => {
    dispatch(getMenu(menu));
  }, [menu]);

  useEffect(() => {
    dispatch(getLastPosts(posts));
  }, [posts]);

  return (
    <>
      {menu && menu.length > 0 && (
        <ContainerStyled>
          <Header />
          <MobileBlankNavigation />
          <ChildrenStyled>{children}</ChildrenStyled>
          <Newsletter />
          <Footer />
        </ContainerStyled>
      )}
    </>
  );
};

export default Layout;
