import styled from "@emotion/styled";
import { palette } from "@/styles/ThemeMui";
import { useTheme, TextField } from "@mui/material";

const LinkColorUnderLined = styled.a`
  color: ${palette.primary
    .contrastText}; /* Defina a cor desejada para o link aqui */
  text-decoration: underline; /* Adicione decoração de sublinhado, se necessário */
`;

const LinkColorNotUnderLined = styled.a`
  color: ${palette.primary
    .contrastText}; /* Defina a cor desejada para o link aqui */
  text-decoration: none; /* Adicione decoração de sublinhado, se necessário */
`;

const LinkColor = (props: any) => {
  const theme = useTheme();
  if (props.underlined) return <LinkColorUnderLined {...props} />;
  else return <LinkColorNotUnderLined {...props} />;
};

export default LinkColor;
