import React, { useEffect, useState } from "react";
import { AppState, dispatch, useSelector } from "@/store/store/Store";
import { useRouter } from "next/router";
import {
  snackClose,
  alertClose,
  changeRetry,
  coreLoadingOn,
  coreLoadingOff,
} from "@/store/reducers/CoreSlice";
import { Alert, Snackbar } from "@mui/material";
import { scrollToTop } from "@/utils/ScrollToTop";
import AlertModal from "@/components/Modal/AlertModal";
import LoadingModal from "@/components/Modal/LoadingModal";
import { usePathname } from "next/navigation";

interface Props {
  children: React.ReactNode;
}

const RouterLoading: React.FC<Props> = ({ children }) => {
  const router = useRouter();

  const { loading, alert, snack, coreLoading } = useSelector(
    (state: AppState) => state.coreReducer
  );

  useEffect(() => {
    const handleRouteChangeStart = () => {
      dispatch(coreLoadingOn());
    };

    const handleRouteChangeComplete = () => {
      dispatch(coreLoadingOff());
      scrollToTop();
    };

    router.events.on("routeChangeStart", handleRouteChangeStart);
    router.events.on("routeChangeComplete", handleRouteChangeComplete);

    return () => {
      router.events.off("routeChangeStart", handleRouteChangeStart);
      router.events.off("routeChangeComplete", handleRouteChangeComplete);
    };
  }, [router]);

  const handleClose = () => {
    dispatch(alertClose());
  };

  const handleTryAgain = () => {
    dispatch(alertClose());
    if (alert.ssr) {
      dispatch(coreLoadingOn());
      router.reload();
    } else {
      dispatch(changeRetry());
    }
  };

  return (
    <>
      {children}
      <LoadingModal open={loading || coreLoading} />

      <AlertModal
        open={alert.status}
        handleClose={handleClose}
        message={alert.message}
        type={alert.type}
        button={alert.button}
        handleTryAgain={handleTryAgain}
      />

      <Snackbar
        open={snack.open}
        autoHideDuration={3000}
        onClose={() => dispatch(snackClose())}
        style={{}}
      >
        <Alert
          variant="filled"
          onClose={() => dispatch(snackClose())}
          severity={snack.severity}
          sx={{ width: "100%" }}
        >
          {snack.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default RouterLoading;
