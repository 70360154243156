import React from "react";
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
} from "@mui/material";
import { MenuType } from "@/store/types/coreTypes";
import { GetIconNew } from "@/utils/GetIcon/GetIconNew";
import Link from "next/link";
import { useRouter } from "next/router";

const FooterLinks = ({ links }: any) => {
  const router = useRouter();
  const scrollToTop = () => {
    if (typeof window !== "undefined") {
      window.scrollTo(0, 0);
    }
  };

  const onClickItem = (slug: string) => {
    scrollToTop();
    router.push(`${slug}`);
  };

  return (
    <>
      <Typography variant="body1" fontSize={"1rem"} fontWeight={700} pl={2}>
        LINKS
      </Typography>

      <List sx={{ bgcolor: "background.paper" }}>
        {links &&
          links.map((item: MenuType, index: number) => (
            <ListItem
              key={index}
              onClick={() => {
                onClickItem(item.href || "/");
              }}
              style={{ cursor: "pointer" }}
            >
              <ListItemAvatar>
                <Avatar
                  sx={{
                    backgroundColor: (theme) =>
                      item.icon == "PlaylistAddIcon"
                        ? theme.palette.terciary.main
                        : theme.palette.secondary.main,
                  }}
                >
                  {GetIconNew(item.icon)}
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={item.name} />
            </ListItem>
          ))}
      </List>
    </>
  );
};

export default FooterLinks;
