import { createSlice } from "@reduxjs/toolkit";
import { userData } from "../types/userTypes";
import api from "@/services/axios";

interface StateType {
  user: userData;
  menu: any;
  error: string;
}

const initialState = {} as StateType;

export const UserSlice: any = createSlice({
  name: "user",
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },
    saveUser: (state, action) => {
      state.user = action.payload;
    },
    saveMenu: (state, action) => {
      state.menu = action.payload;
    },
  },
});
export const { hasError, saveUser, saveMenu } = UserSlice.actions;

export const putUserGeneric = async (
  data: any,
  type: string,
  callback: any,
  errocallback: any
) => {
  await api
    .put(`/user/${type}`, data)
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      if (errocallback != null) {
        errocallback(err);
      }
    });
};

export default UserSlice.reducer;
