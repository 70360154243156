import React from "react";
import { useTheme } from "@mui/material/styles";
import { useRouter } from "next/router";

import {
  ListItemIcon,
  styled,
  ListItemText,
  Box,
  ListItemButton,
} from "@mui/material";
import { AppState, useSelector } from "@/store/store/Store";
import NavItem from "./NavItem";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import { GetIconNew } from "@/utils/GetIcon/GetIconNew";

type NavGroupProps = {
  [x: string]: any;
  navlabel?: boolean;
  subheader?: string;
  name?: string;
  icon?: string;
  href?: any;
};

interface NavCollapseProps {
  menu: NavGroupProps;
  level: number;
  pathWithoutLastPart: any;
  pathDirect: any;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

// FC Component For Dropdown Menu
const NavCollapse = ({
  menu,
  level,
  pathWithoutLastPart,
  pathDirect,
  onClick,
}: NavCollapseProps) => {
  const Icon: any = GetIconNew(menu.icon, { fontSize: "1.1rem !important" });
  const theme = useTheme();
  const { pathname, query } = useRouter();
  const [open, setOpen] = React.useState(false);
  const customizer = useSelector((state: AppState) => state.customizer);

  const hasSlug = Boolean(query.slug);
  const pathnameWithoutSlug =
    hasSlug && pathname.replace(/\/\[slug\](\/|$)/, (match, p1) => p1);
  const fullPathWithSlug = hasSlug
    ? `${pathnameWithoutSlug}/${query.slug}`
    : pathname;

  React.useEffect(() => {
    setOpen(false);

    menu.children.forEach((item: any) => {
      if (item.href === fullPathWithSlug) {
        setOpen(true);
      }
    });
  }, [fullPathWithSlug, menu.children]);

  const ListItemStyled = styled(ListItemButton)(() => ({
    width: "auto",
    padding: "5px 10px",
    position: "relative",
    flexGrow: "unset",
    gap: "5px",

    borderRadius: `${customizer.borderRadius}px`,
    whiteSpace: "nowrap",
    color:
      open || pathname.includes(menu.href) || level < 1
        ? "white"
        : theme.palette.text.secondary,
    backgroundColor:
      open || pathname.includes(menu.href) ? theme.palette.primary.main : "",

    "&:hover": {
      backgroundColor:
        open || pathname.includes(menu.href)
          ? theme.palette.primary.main
          : theme.palette.primary.light,
    },
    "&:hover > .SubNav": { display: "block" },
  }));

  const ListSubMenu = styled(Box)(() => ({
    display: "none",
    position: "absolute",
    top: level > 1 ? `0px` : "35px",
    left: level > 1 ? `${level + 228}px` : "0px",
    padding: "10px",
    width: "250px",
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[8],
    backgroundColor: theme.palette.background.paper,
    borderRadius: customizer.borderRadius,
  }));

  const listItemProps: {
    component: string;
  } = {
    component: "li",
  };

  // If Menu has Children
  const submenus = menu.children?.map((item: any, index: number) => {
    if (item.children) {
      return (
        <NavCollapse
          key={index}
          menu={item}
          level={level + 1}
          pathWithoutLastPart={pathWithoutLastPart}
          pathDirect={pathDirect}
          onClick={onClick}
        />
      );
    } else {
      return (
        <NavItem
          key={index}
          item={item}
          level={level + 1}
          pathDirect={pathDirect}
          onClick={onClick}
        />
      );
    }
  });

  return (
    <React.Fragment key={menu.id}>
      <ListItemStyled
        {...listItemProps}
        selected={pathWithoutLastPart === menu.href}
        className={open ? "selected" : ""}
      >
        <ListItemIcon
          sx={{
            minWidth: "auto",
            p: "3px 0",
            color: "inherit",
          }}
        >
          {Icon}
        </ListItemIcon>
        <ListItemText color="inherit" sx={{ mr: "auto" }}>
          {menu.name}
        </ListItemText>
        <ExpandMoreOutlinedIcon
          sx={{ strokeWidth: 1, fontSize: "1rem !important" }}
        />

        <ListSubMenu component={"ul"} className="SubNav">
          {submenus}
        </ListSubMenu>
      </ListItemStyled>
    </React.Fragment>
  );
};

export default NavCollapse;
