import yup from "@/utils/yupConfig";

export const initialState = {
  emailnews: "",
  namenews: "",
  agreeToPrivacyPolicy: false,
};

export const schema = yup.object().shape({
  emailnews: yup.string().email().required(),
  namenews: yup.string().min(3).required(),
  agreeToPrivacyPolicy: yup
    .boolean()
    .oneOf([true], "Você deve concordar com a Política de Privacidade")
    .required(),
});
