import React, { ReactNode, useState } from "react";
import {
  Box,
  Container,
  useTheme,
  Hidden,
  Stack,
  useMediaQuery,
  Theme,
  styled,
} from "@mui/material";
import MemberMenu from "@/sections/_Layout/Menu/MemberMenu";
import PrivateRoute from "./PrivateRoute";
import Header from "@/sections/_Layout/Header/Header";
import Breadcrumb from "@/sections/Breadcrumb/Breadcrumb";

interface MemberSectionProps {
  children: ReactNode;
  top?: ReactNode;
}

const ContainerStyled = styled("div")(() => ({
  display: "flex",
  minHeight: "100vh",
  width: "100%",
  flexDirection: "column",
}));

const ChildrenStyled = styled(Box)(({ theme }) => ({
  flex: "1",
  paddingBottom: "20px",
}));

const LayoutMember = ({ children, top }: MemberSectionProps) => {
  const theme = useTheme();
  const [isMobileSidebarOpen, setMobileSidebarOpen] = React.useState(true);
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));

  return (
    <>
      <PrivateRoute>
        <ContainerStyled>
          <Header />
          {/* <MobileAdminNavigation groupMenu={"Admin"} /> */}

          <Box bgcolor={theme.palette.white.main} py={3}>
            <Container maxWidth="lg">
              <Box py={2}>
                <Breadcrumb />
              </Box>
              <Stack direction="row" gap={mdUp ? 3 : 0}>
                <Hidden mdDown>
                  <MemberMenu
                    isMobileSidebarOpen={isMobileSidebarOpen}
                    onSidebarClose={() => setMobileSidebarOpen(false)}
                  />
                </Hidden>
                <Box flexGrow={1}>{children}</Box>
              </Stack>
            </Container>
          </Box>
        </ContainerStyled>
      </PrivateRoute>
    </>
  );
};

export default LayoutMember;
