import React from "react";
import { MenuList } from "@mui/material";
import MobileMainNavItem from "./MobileNavItem";
import { MenuType } from "@/store/types/coreTypes";

interface MobileNavListType {
  menu: MenuType[];
}

const MobileMainNavList = ({ menu }: MobileNavListType) => {
  return (
    <>
      <MenuList>
        {menu.map((item: MenuType, index: React.Key | null | undefined) => (
          <MobileMainNavItem key={index} item={item} />
        ))}
      </MenuList>
    </>
  );
};

export default MobileMainNavList;
