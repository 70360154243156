import react from "react";
import {
  Box,
  Button,
  Container,
  Hidden,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import SpanColor from "../../../components/common/SpanColor/SpanColor";
import Image from "next/image";
import Logo from "../Header/logo";

const FooterAbout = () => {
  return (
    <>
      <Hidden smDown>
        <Image
          src="/logo.png"
          width={267}
          height={45}
          alt="Logo"
          priority
          quality={100}
        />
      </Hidden>
      <Hidden smUp>
        <Image
          src="/logo.png"
          width={210}
          height={35}
          alt="Logo"
          priority
          quality={100}
          style={{ marginTop: "6px" }}
        />
      </Hidden>
      <Typography mt={3} mb={3}>
        Aqui você encontra materiais exclusivos para entreter e desenvolver
        seu(s) filho(s).
      </Typography>
      <Typography mt={3} mb={3}>
        Venha se divertir e aprender com a Hora da Atividade!
      </Typography>
    </>
  );
};

export default FooterAbout;
