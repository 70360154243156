import {
  useTheme,
  TextField,
  Breadcrumbs,
  Link,
  Typography,
  Container,
  Box,
} from "@mui/material";
import React from "react";
import { useRouter } from "next/router";
import { breadcrumbItem } from "@/store/types/breadcrumbTypes";
import { capitalizedWords } from "@/utils/capitalizedWords";

interface BreadcrumbProps {
  data?: breadcrumbItem[];
}

const Breadcrumb = ({ data }: BreadcrumbProps) => {
  const router = useRouter();

  if (data && data.length > 0) {
    // Se 'data' estiver presente em props e não estiver vazio, use-o para renderizar os breadcrumbs
    const totalData = data.length - 1;
    return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        {data.map((breadcrumb, index) =>
          // Verifica se a URL está presente antes de renderizar o link
          // Se não houver uma URL, ele renderizará apenas o título
          totalData > index ? (
            <Link
              underline="hover"
              // color="primary"
              color="#000"
              href={breadcrumb.href}
              key={breadcrumb.id}
              style={{ fontWeight: "700" }}
            >
              {breadcrumb.name}
            </Link>
          ) : (
            <Typography
              // color="text.primary"
              key={breadcrumb.id}
              color="#000"
            >
              {breadcrumb.name}
            </Typography>
          )
        )}
      </Breadcrumbs>
    );
  } else {
    let breadcrumbs: breadcrumbItem[] = [];
    const pathAfterDomain = router.asPath;

    const pathSplitted = pathAfterDomain.split("/").filter(Boolean); // Remove segmentos vazios

    // Adicione a página inicial como "Home" (seguindo o seu requisito)
    breadcrumbs.push({
      id: 0,
      name: "Home",
      href: "/",
    });

    const getTitle = (segment: string) => {
      if (segment == "category") return "Categorias";
      if (segment == "search") return "Pesquisa";
      if (segment == "search-date") return;
      if (segment == "store") return "Loja";
      if (segment == "member") return;
      if (segment == "account") return "Minha Conta";

      const capitalizedWord = capitalizedWords(segment);

      return capitalizedWord;
    };

    for (let index = 0; index < pathSplitted.length; index++) {
      let segment = pathSplitted[index];
      const parts = segment.split("?");
      segment = parts[0];
      const href = `/${pathSplitted.slice(0, index + 1).join("/")}`;

      if (segment != "post" && getTitle(segment) != undefined) {
        breadcrumbs.push({
          id: index + 1,
          name: getTitle(segment) ?? "",
          href: href,
        });
      }
    }

    const lastBreadcrumb = breadcrumbs.pop();
    return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        {breadcrumbs.map((breadcrumb) => (
          <Link
            underline="hover"
            // color="primary"
            href={breadcrumb.href}
            key={breadcrumb.id}
          >
            {breadcrumb.name}
          </Link>
        ))}
        <Typography
        // color="text.primary"
        >
          {lastBreadcrumb?.name}
        </Typography>
      </Breadcrumbs>
    );
  }
};

export default Breadcrumb;
