import React, { useState } from "react";
import { Grid, Button, Typography, Stack, Checkbox } from "@mui/material";
import InputIcon from "@/components/Form/InputIcon";
import { handleSubmit } from "@/utils/handleSubmit/handleSubmit";
import { initialState, schema } from "./NewsletterSchema";
import LinkColor from "@/components/common/LinkColor/LinkColor";
import { StackNewsletterFormStyled } from "./style";

interface NewsletterFormProps {
  onSubmitForm: (e: any) => void;
}

const NewsletterForm: React.FC<NewsletterFormProps> = ({ onSubmitForm }) => {
  const [formData, setFormData] = useState(initialState);
  const [errors, setErrors] = useState(initialState);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setFormData((prevFormData: any) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const onSubmitSuccess = () => {
    setErrors(initialState);
    onSubmitForm(formData);
  };

  const onSubmitFail = (errors: any) => {
    setErrors(errors);
  };

  const handleSubmitForm = (event: any) => {
    event.preventDefault();
    handleSubmit({ schema, formData, onSubmitSuccess, onSubmitFail });
  };

  const handleChangeCheckbox = (event: {
    target: { name: any; checked: any };
  }) => {
    const { name, checked } = event.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: checked,
    }));
  };

  return (
    <>
      <form onSubmit={handleSubmitForm}>
        <StackNewsletterFormStyled mt={3} gap={2}>
          <Stack direction="column">
            <InputIcon
              placeholder="Digite seu nome"
              type="name"
              onChange={handleChange}
              value={formData.namenews}
              name="namenews"
            />
            {errors.namenews && (
              <Typography ml={1} variant={"caption"} color={"error"}>
                * {errors.namenews}
              </Typography>
            )}
          </Stack>
          <Stack direction="column">
            <InputIcon
              placeholder="Digite seu email"
              type="email"
              onChange={handleChange}
              value={formData.emailnews}
              name="emailnews"
            />
            {errors.emailnews && (
              <Typography ml={1} variant={"caption"} color={"error"}>
                * {errors.emailnews}
              </Typography>
            )}
          </Stack>
          <Button
            color="primary"
            variant="contained"
            size="medium"
            id="button-news"
            type="submit"
            style={{ height: "45px" }}
          >
            Assinar
          </Button>
        </StackNewsletterFormStyled>
        <Stack mt={3} direction={"row"} alignItems={"center"}>
          <Checkbox
            color="primary"
            id="checkbox-news"
            name="agreeToPrivacyPolicy"
            checked={formData.agreeToPrivacyPolicy}
            onChange={handleChangeCheckbox}
            inputProps={{
              "aria-label": "Aceite da Política de Privacidade e Termos de Uso",
              "aria-checked": formData.agreeToPrivacyPolicy ? "true" : "false",
              role: "checkbox",
            }}
          />
          <Typography variant="body1">
            Estou de acordo com a{" "}
            <LinkColor
              href="/politica-de-privacidade"
              underlined
              target="_blank"
            >
              Política de Privacidade
            </LinkColor>{" "}
            e os{" "}
            <LinkColor href="/termos-de-uso" underlined target="_blank">
              Termos de Uso
            </LinkColor>
            .
          </Typography>
        </Stack>
        {errors.agreeToPrivacyPolicy && (
          <Typography ml={1} variant={"caption"} color={"error"}>
            * {errors.agreeToPrivacyPolicy}
          </Typography>
        )}
      </form>
    </>
  );
};

export default NewsletterForm;
