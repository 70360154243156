import React, { useState } from "react";
import {
  Box,
  Stack,
  Avatar,
  Typography,
  IconButton,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useAuthContext } from "@/store/auth/AuthContext";
import { AppState, useSelector } from "@/store/store/Store";

interface UserInfoLateralType {
  handleDrawerClose: any;
}

const UserInfoLateral = ({ handleDrawerClose }: UserInfoLateralType) => {
  const theme = useTheme();

  const [openModalSign, setOpenModalSign] = useState(false);
  const { authenticated, signIn, signOut } = useAuthContext();

  const { user } = useSelector((state: AppState) => state.user);

  const firstname = user && user.name ? user.name.split(" ") : "";

  const logOut = () => {
    signOut();
  };
  const handleAfterLogin = () => {
    setOpenModalSign(false);
  };

  const handleCloseModal = () => {
    setOpenModalSign(false);
  };

  return (
    <>
      <Box position={"relative"}>
        {/* <Stack direction="row" spacing={1} alignItems={"center"}>
          {authenticated && <Avatar src={user?.picture} />}
          {!authenticated && <Avatar />}
          <Box>
            <Typography variant="body1" color="primary" fontWeight={700}>
              {authenticated ? `Olá, ${firstname[0]}` : "Olá, Visitante!"}
            </Typography>
            {!authenticated && (
              <Typography
                variant="body2"
                color="black"
                onClick={() => setOpenModalSign(true)}
              >
                <b>Entrar</b> ou <b>Cadastrar</b>
              </Typography>
            )}
          </Box>
        </Stack> */}
        <Box position={"absolute"} style={{ top: "-10px", right: "-10px" }}>
          {/* <IconButton size="large" onClick={handleDrawerClose}>
            <CloseIcon
              fontSize="small"
              style={{ color: theme.palette.primary.main }}
            />
          </IconButton> */}
        </Box>
      </Box>
    </>
  );
};

export default UserInfoLateral;
