import react from "react";
import Image from "next/image";

const LogoLinks = () => {
  return (
    <>
      <Image
        src="/logo.png"
        width={210}
        height={35}
        alt="Logo"
        priority
        quality={100}
        style={{ marginTop: "6px" }}
      />
    </>
  );
};

export default LogoLinks;
