import { Typography, useTheme } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuList from "@mui/material/MenuList";
import { useRouter } from "next/router";
import { MenuItemStyled } from "./style";
import { MenuType } from "@/store/types/coreTypes";

interface DesktopMainNavItemType {
  item: MenuType;
  subitem?: boolean;
  closeMenu?: () => void;
  zIndex?: number;
}

const DesktopMainNavItem = ({
  item,
  subitem,
  closeMenu,
  zIndex = 100, // Inicialize o zIndex como 1
}: DesktopMainNavItemType) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const router = useRouter();
  const theme = useTheme();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const closeMenuInicial = () => {
    setOpen(false);
  };

  const goTo = async (href: any) => {
    const currentURL = router.asPath;

    if (href !== currentURL) {
      try {
        await router.push(href);
      } finally {
        if (closeMenu) closeMenu();
      }
    } else {
      console.log("Você está tentando navegar para a mesma URL.");
    }
  };

  const getSelected = (href: string | undefined) => {
    const currentURL = router.asPath;

    if (href == undefined) return false;

    if (currentURL.includes(href)) return true;
    return false;
  };

  return (
    <>
      {(!item.children || item.children.length == 0) && (
        <Typography
          color="black"
          fontFamily={"Nunito"}
          fontWeight={600}
          fontSize={16}
          style={{
            paddingLeft: "10px",
            paddingRight: "15px",
            width: subitem ? "100%" : "unset",
            minWidth: subitem ? "150px" : "unset",
            cursor: "pointer",

            backgroundColor:
              getSelected(item.href) && !subitem
                ? theme.palette.secondary.extraLight
                : "unset",
            borderRadius: "5px",
            paddingTop: !subitem ? "5px" : "unset",
            paddingBottom: !subitem ? "5px" : "unset",
          }}
          onClick={() => goTo(item.href)}
        >
          {item.name}
        </Typography>
      )}
      {item.children && item.children.length > 0 && (
        <div
          onMouseEnter={handleOpen}
          onMouseLeave={handleToggle}
          style={{
            width: subitem ? "100%" : "unset",
            minWidth: subitem ? "150px" : "unset",
            cursor: "pointer",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              backgroundColor:
                getSelected(item.href) && !subitem
                  ? theme.palette.secondary.extraLight
                  : "unset",
              borderRadius: "5px",
              paddingTop: !subitem ? "5px" : "unset",
              paddingBottom: !subitem ? "5px" : "unset",
            }}
            ref={anchorRef}
            id="composition-button"
            aria-controls={open ? "composition-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
          >
            <Typography
              color="black"
              fontFamily="Nunito"
              fontWeight={600}
              fontSize={15}
              style={{ marginLeft: "10px" }}
              onClick={() => goTo(item.href)}
            >
              {item.name}
            </Typography>
            <ExpandMoreIcon
              style={{
                marginLeft: "5px",
                marginRight: subitem ? "10px" : "5px",
              }}
            />
          </div>

          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            placement={subitem ? "right-start" : "bottom-start"}
            transition
            disablePortal
            style={{ zIndex: zIndex }} // Defina o zIndex aqui
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: "left top",
                }}
              >
                <Paper elevation={4}>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={open}
                      id="composition-menu"
                      aria-labelledby="composition-button"
                    >
                      {item.children?.map((data, index) => (
                        <MenuItemStyled
                          key={index}
                          selected={getSelected(data.href)}
                        >
                          <DesktopMainNavItem
                            item={data}
                            subitem
                            closeMenu={subitem ? closeMenu : closeMenuInicial}
                            zIndex={zIndex + 1}
                          />
                        </MenuItemStyled>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      )}
    </>
  );
};

export default DesktopMainNavItem;
