import axios from "axios";
import { backendHost } from "./config";

const api = axios.create({
  baseURL: backendHost(),
});

// api.interceptors.request.use((config) => {
//   config.headers["Cache-Control"] = "no-cache";
//   return config;
// });

api.interceptors.response.use(
  (response: any) => response,
  (error: { response: { status: number; data: any } }) => {
    if (error.response && error.response.status === 401) {
      // sessionStorage.removeItem("tokenGoogle");
      window.location.reload();
    }
    return Promise.reject(
      (error.response && error.response.data) || "Wrong Services"
    );
  }
);

export default api;
