import { createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "../store/Store";
import api from "@/services/axios";

interface StateType {
  search: string;
}

const initialState = {
  search: "",
} as StateType;

export const MemberSlice = createSlice({
  name: "Member",
  initialState,
  reducers: {
    // getPost: (state: StateType, action) => {
    //   state.post = action.payload;
    // },
  },
});

export const {} = MemberSlice.actions;

export const fetchPostNewsletter = async (
  data: any,
  callback: any,
  errocallback: any
) => {
  const config = {
    name: data.namenews,
    email: data.emailnews,
  };
  await api
    .post(`member/newsletter`, config)
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      if (errocallback != null) {
        errocallback(err);
      }
    });
};

export const fetchPosts = async (callback: any, errocallback: any) => {
  await api
    .get("member/posts", {
      // headers: {
      //   "Cache-Control": "no-cache", // Adicione este cabeçalho para desativar o cache
      // },
    })
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      if (errocallback != null) {
        errocallback(err);
      }
    });
};

export const fetchDisplays = async (callback: any, errocallback: any) => {
  await api
    .get("member/display", {
      headers: {
        "Cache-Control": "no-cache", // Adicione este cabeçalho para desativar o cache
      },
    })
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      if (errocallback != null) {
        errocallback(err);
      }
    });
};

export const fetchDisplay = async (
  slug: any,
  callback: any,
  errocallback: any
) => {
  await api
    .get(`member/display/${slug}`)
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      if (errocallback != null) {
        errocallback(err);
      }
    });
};

export const fetchPost = async (
  slug: any,
  callback: any,
  errocallback: any
) => {
  await api
    .get(`member/post/${slug}`)
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      if (errocallback != null) {
        errocallback(err);
      }
    });
};

export const putPost = async (
  formData: any,
  slug: any,
  callback: any,
  errocallback: any
) => {
  await api
    .put(`member/post/${slug}`, formData)
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      if (errocallback != null) {
        errocallback(err);
      }
    });
};

export const putDisplay = async (
  formData: any,
  slug: any,
  callback: any,
  errocallback: any
) => {
  await api
    .put(`member/display/${slug}`, formData)
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      if (errocallback != null) {
        errocallback(err);
      }
    });
};

export const putCategory = async (
  formData: any,
  id: any,
  callback: any,
  errocallback: any
) => {
  await api
    .put(`member/category/${id}`, formData)
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      if (errocallback != null) {
        errocallback(err);
      }
    });
};

export const putGame = async (
  formData: any,
  id: any,
  callback: any,
  errocallback: any
) => {
  await api
    .put(`member/game/${id}`, formData)
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      if (errocallback != null) {
        errocallback(err);
      }
    });
};

export const putLevel = async (
  formData: any,
  id: any,
  callback: any,
  errocallback: any
) => {
  await api
    .put(`member/level/${id}`, formData)
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      if (errocallback != null) {
        errocallback(err);
      }
    });
};

export const putTask = async (
  formData: any,
  id: any,
  callback: any,
  errocallback: any
) => {
  await api
    .put(`member/task/${id}`, formData)
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      if (errocallback != null) {
        errocallback(err);
      }
    });
};

export const postPost = async (
  formData: any,
  callback: any,
  errocallback: any
) => {
  await api
    .post(`member/post`, formData)
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      if (errocallback != null) {
        errocallback(err);
      }
    });
};

export const postDisplay = async (
  formData: any,
  callback: any,
  errocallback: any
) => {
  await api
    .post(`member/display`, formData)
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      if (errocallback != null) {
        errocallback(err);
      }
    });
};

export const postGame = async (
  formData: any,
  callback: any,
  errocallback: any
) => {
  await api
    .post(`member/game`, formData)
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      if (errocallback != null) {
        errocallback(err);
      }
    });
};

export const postLevel = async (
  formData: any,
  callback: any,
  errocallback: any
) => {
  await api
    .post(`member/level`, formData)
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      if (errocallback != null) {
        errocallback(err);
      }
    });
};

export const postTask = async (
  formData: any,
  callback: any,
  errocallback: any
) => {
  await api
    .post(`member/task`, formData)
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      if (errocallback != null) {
        errocallback(err);
      }
    });
};

export const postCategory = async (
  formData: any,
  callback: any,
  errocallback: any
) => {
  await api
    .post(`member/category`, formData)
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      if (errocallback != null) {
        errocallback(err);
      }
    });
};

export const fetchFiles = async (callback: any, errocallback: any) => {
  await api
    .get("member/files", {
      headers: {
        "Cache-Control": "no-cache", // Adicione este cabeçalho para desativar o cache
      },
    })
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      if (errocallback != null) {
        errocallback(err);
      }
    });
};

export const fetchGames = async (callback: any, errocallback: any) => {
  await api
    .get("member/games", {
      headers: {
        "Cache-Control": "no-cache",
      },
    })
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      if (errocallback != null) {
        errocallback(err);
      }
    });
};

export const fetchCategories = async (callback: any, errocallback: any) => {
  await api
    .get("member/categories", {
      headers: {
        "Cache-Control": "no-cache", // Adicione este cabeçalho para desativar o cache
      },
    })
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      if (errocallback != null) {
        errocallback(err);
      }
    });
};

export const fetchImage = async (id: any, callback: any, errocallback: any) => {
  await api
    .get(`member/image/${id}`)
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      if (errocallback != null) {
        errocallback(err);
      }
    });
};

export const fetchFile = async (id: any, callback: any, errocallback: any) => {
  await api
    .get(`member/file/${id}`)
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      if (errocallback != null) {
        errocallback(err);
      }
    });
};

export const fetchCategory = async (
  id: any,
  callback: any,
  errocallback: any
) => {
  await api
    .get(`member/category/${id}`)
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      if (errocallback != null) {
        errocallback(err);
      }
    });
};

export const fetchGame = async (id: any, callback: any, errocallback: any) => {
  await api
    .get(`member/game/${id}`)
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      if (errocallback != null) {
        errocallback(err);
      }
    });
};

export const fetchLevel = async (id: any, callback: any, errocallback: any) => {
  await api
    .get(`member/level/${id}`)
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      if (errocallback != null) {
        errocallback(err);
      }
    });
};

export const fetchTask = async (id: any, callback: any, errocallback: any) => {
  await api
    .get(`member/task/${id}`)
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      if (errocallback != null) {
        errocallback(err);
      }
    });
};

export const fetchImages = async (callback: any, errocallback: any) => {
  await api
    .get("member/images", {
      headers: {
        "Cache-Control": "no-cache", // Adicione este cabeçalho para desativar o cache
      },
    })
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      if (errocallback != null) {
        errocallback(err);
      }
    });
};

export const fetchImageList = async (callback: any, errocallback: any) => {
  await api
    .get("member/imageList")
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      if (errocallback != null) {
        errocallback(err);
      }
    });
};

export const fetchGameList = async (callback: any, errocallback: any) => {
  await api
    .get("member/gameList")
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      if (errocallback != null) {
        errocallback(err);
      }
    });
};

export const fetchDisplayList = async (callback: any, errocallback: any) => {
  await api
    .get(`member/displayList`)
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      if (errocallback != null) {
        errocallback(err);
      }
    });
};

export const fetchCategoryList = async (
  hidden: any,
  callback: any,
  errocallback: any
) => {
  await api
    .get(`member/categoryList?hidden=${hidden}`)
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      if (errocallback != null) {
        errocallback(err);
      }
    });
};

export const fetchFileList = async (callback: any, errocallback: any) => {
  await api
    .get("member/fileList")
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      if (errocallback != null) {
        errocallback(err);
      }
    });
};

export const fetchPostList = async (callback: any, errocallback: any) => {
  await api
    .get("member/postList")
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      if (errocallback != null) {
        errocallback(err);
      }
    });
};

export const putImage = async (
  formInfo: any,
  id: any,
  callback: any,
  errocallback: any
) => {
  const formData = new FormData();
  formData.append("name", formInfo.name);
  formData.append("type", formInfo.type);
  formData.append("altText", formInfo.altText);
  formData.append("source", formInfo.source);
  formData.append("smart", formInfo.smart);
  formData.append("image", formInfo.file);

  await api
    .put(`member/image/${id}`, formData)
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      if (errocallback != null) {
        errocallback(err);
      }
    });
};

export const postFile = async (
  formInfo: any,
  callback: any,
  errocallback: any
) => {
  const formData = new FormData();
  formData.append("pdf", formInfo.file);
  formData.append("name", formInfo.name);
  formData.append("imageId", formInfo.image.value);
  formData.append("smart", formInfo.smart);

  await api
    .post("member/file", formData)
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      if (errocallback != null) {
        errocallback(err);
      }
    });
};

export const putFile = async (
  formInfo: any,
  id: any,
  callback: any,
  errocallback: any
) => {
  const formData = new FormData();

  formData.append("name", formInfo.name);
  formData.append("imageId", formInfo.image.value);
  formData.append("smart", formInfo.smart);
  formData.append("pdf", formInfo.file);

  await api
    .put(`member/file/${id}`, formData)
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      if (errocallback != null) {
        errocallback(err);
      }
    });
};

export const postImage = async (
  formInfo: any,
  callback: any,
  errocallback: any
) => {
  const formData = new FormData();

  formData.append("name", formInfo.name); // Adicione os dados do formulário
  formData.append("type", formInfo.type); // Adicione os dados do formulário
  formData.append("altText", formInfo.altText);
  formData.append("source", formInfo.source);
  formData.append("smart", formInfo.smart);
  formData.append("image", formInfo.file);

  await api
    .post("member/image", formData)
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      if (errocallback != null) {
        errocallback(err);
      }
    });
};

export const deleteImage = async (
  id: any,
  callback: any,
  errocallback: any
) => {
  await api
    .delete(`member/image/${id}`)
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      if (errocallback != null) {
        errocallback(err);
      }
    });
};

export const deleteGame = async (
  type: number,
  id: any,
  callback: any,
  errocallback: any
) => {
  await api
    .delete(
      type == 0
        ? `member/game/${id}`
        : type == 1
        ? `member/level/${id}`
        : `member/task/${id}`
    )
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      if (errocallback != null) {
        errocallback(err);
      }
    });
};

export const deletePost = async (id: any, callback: any, errocallback: any) => {
  await api
    .delete(`member/post/${id}`)
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      if (errocallback != null) {
        errocallback(err);
      }
    });
};

export const deleteDisplay = async (
  id: any,
  callback: any,
  errocallback: any
) => {
  await api
    .delete(`member/display/${id}`)
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      if (errocallback != null) {
        errocallback(err);
      }
    });
};

export const deleteFile = async (id: any, callback: any, errocallback: any) => {
  await api
    .delete(`member/file/${id}`)
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      if (errocallback != null) {
        errocallback(err);
      }
    });
};

export const deleteCategory = async (
  id: any,
  callback: any,
  errocallback: any
) => {
  await api
    .delete(`member/category/${id}`)
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      if (errocallback != null) {
        errocallback(err);
      }
    });
};

export default MemberSlice.reducer;
