import React, { useState } from "react";
import { Hidden, Skeleton, Stack } from "@mui/material";
import DesktopMainNavItem from "./DesktopMainNavItem";
import { MenuType } from "@/store/types/coreTypes";
import { AppState, useSelector } from "@/store/store/Store";

const DesktopMainNavList = () => {
  const menu: MenuType[] = useSelector(
    (state: AppState) => state.coreReducer.menu
  );

  return (
    <Hidden mdDown>
      {menu.length > 0 && (
        <Stack flexDirection={"row"} alignItems={"center"}>
          {menu.map((item, index) => (
            <DesktopMainNavItem item={item} key={index} />
          ))}
        </Stack>
      )}
      {menu.length == 0 && (
        <Stack flexDirection={"row"} gap={2} alignItems={"center"}>
          <Skeleton variant="rounded" width={120} height={20} />
          <Skeleton variant="rounded" width={120} height={20} />
          <Skeleton variant="rounded" width={120} height={20} />
        </Stack>
      )}
    </Hidden>
  );
};

export default DesktopMainNavList;
