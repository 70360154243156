import api from "@/services/axios";
import { AlertType, MenuType, SnackType } from "@/store/types/coreTypes";

import { createSlice } from "@reduxjs/toolkit";

interface StateType {
  coreLoading: boolean;
  loading: boolean;
  alert: AlertType;
  snack: SnackType;
  retry: boolean;
  menu: MenuType[];
  memberMenu: any;
}

const initialState = {
  coreLoading: false,
  loading: false,
  alert: {
    status: false,
    local: "",
    type: "NONE",
    message: "",
    button: false,
    ssr: false,
  },
  snack: {
    open: false,
    severity: "success",
    message: "",
  },
  retry: false,
  menu: [],
  memberMenu: [],
} as StateType;

const CoreSlice = createSlice({
  name: "core",
  initialState,
  reducers: {
    loadingOn(state: StateType) {
      state.loading = true;
    },
    loadingOff(state: StateType) {
      state.loading = false;
    },
    coreLoadingOn(state: StateType) {
      state.coreLoading = true;
    },
    coreLoadingOff(state: StateType) {
      state.coreLoading = false;
    },
    alertClose(state: StateType) {
      state.alert = {
        status: false,
        // local: "",
        type: "NONE",
        message: "",
        button: false,
        ssr: false,
      };
    },

    alertOpen(state: StateType, action) {
      state.alert = {
        status: true,
        // local: action.payload.local,
        type: action.payload.type,
        message: action.payload.message,
        button: action.payload.button,
      };
    },
    snackOpen(state: StateType, action) {
      state.snack = {
        open: true,
        severity: action.payload.severity,
        message: action.payload.message,
      };
    },
    snackClose(state: StateType) {
      const oldSnack = state.snack;
      state.snack = {
        ...oldSnack,
        open: false,
      };
    },

    alertOps(state: StateType) {
      state.alert = {
        status: true,
        type: "OPS",
        // local: "",
        message: "Ocorreu um erro em nossos servidores!",
        button: true,
        ssr: false,
      };
    },

    alertOpsSSR(state: StateType) {
      state.alert = {
        status: true,
        type: "OPS",
        // local: "",
        message: "Ocorreu um erro em nossos servidores!",
        button: true,
        ssr: true,
      };
    },

    changeRetry(state: StateType) {
      const oldRetry = state.retry;
      state.retry = !oldRetry;
    },

    getMenu(state: StateType, action) {
      state.menu = action.payload;
    },

    getMemberMenu(state: StateType, action) {
      state.memberMenu = action.payload;
    },
  },
});

export const {
  loadingOn,
  loadingOff,
  coreLoadingOn,
  coreLoadingOff,
  alertClose,
  alertOpen,
  alertOps,
  snackOpen,
  snackClose,
  alertOpsSSR,
  changeRetry,
  getMenu,
  getMemberMenu,
} = CoreSlice.actions;

// export const fetchMenu = async (callback: any, errocallback: any) => {
//   await api
//     .get(`menu`)
//     .then((res) => {
//       if (callback != null) {
//         callback(res);
//       }
//     })
//     .catch((err) => {
//       if (errocallback != null) {
//         errocallback(err);
//       }
//     });
// };

export const fetchMenuStatic = async () => {
  try {
    const response = await api.get(`menu`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchMemberMenu = async (callback: any, errocallback: any) => {
  await api
    .get("member/menu")
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      if (errocallback != null) {
        errocallback(err);
      }
    });
};

export default CoreSlice.reducer;
