import react, { useEffect } from "react";
import { Box, Container, Typography } from "@mui/material";
import SpanColor from "../../../components/common/SpanColor/SpanColor";
import Link from "next/link";

const FooterCopyright = () => {
  const dataAtual = new Date();
  const anoAtual = dataAtual.getFullYear();

  return (
    <div style={{ borderTop: "1px solid #EBEBEB" }}>
      <Container maxWidth="lg">
        <Box paddingY={1}>
          <Typography>
            © {anoAtual}{" "}
            <Link href="https://www.horadaatividade.com.br">
              Hora da Atividade{" "}
            </Link>
            {/* <a onClick={handleDownloadAndRedirect}>Hora da Atividade </a> */}
            {/* | Desenvolvido por{" "}
            <Link href="https://www.traxdigital.com.br" target="_blank">
              TraxDigital
            </Link> */}
          </Typography>
        </Box>
      </Container>
    </div>
  );
};

export default FooterCopyright;
