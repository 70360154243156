import { createSlice } from "@reduxjs/toolkit";

import { ProductType, CategoryType } from "../types/storeTypes";
import api from "@/services/axios";

interface StateType {
  product: ProductType;
  products: ProductType[];
  categories: CategoryType[];
  //   newPost: PostType;
  //   lastPosts: PostType[];
  //   categories: CategoryType[];
}

const initialState = {
  product: {} as ProductType,
  products: [],
  categories: [],
  //   newPost: {} as PostType,
  //   lastPosts: [],
  //   categories: [],
} as StateType;

export const BlogSlice = createSlice({
  name: "Blog",
  initialState,
  reducers: {
    getProduct: (state: StateType, action) => {
      state.product = action.payload;
    },

    getProducts: (state: StateType, action) => {
      state.products = action.payload;
    },

    getCategories: (state: StateType, action) => {
      state.categories = action.payload;
    },
  },
});

export const { getProduct, getProducts, getCategories } = BlogSlice.actions;

/////Devolve erro para abrir o modal
export const fetchProductStatic = async (slug: string) => {
  try {
    const response = await api.get(`store/product`);
    // const response = await api.get(`blog/post/${slug}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

//////Devolve erro para abrir o modal
export const fetchProductsStatic = async () => {
  try {
    const response = await api.get(`store/products`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

//////Não devolve erro. Trata de forma amigável
// export const fetchLastPosts = async (callback: any, errocallback: any) => {
//   await api
//     .get(`blog/last-posts`)
//     .then((res) => {
//       if (callback != null) {
//         callback(res);
//       }
//     })
//     .catch((err) => {
//       if (errocallback != null) {
//         errocallback(err);
//       }
//     });
// };

//////Devolve erro para abrir o modal
// export const fetchCategoriesStatic = async () => {
//   try {
//     const response = await api.get(`store/categories`);
//     return response.data;
//   } catch (error) {
//     throw error;
//   }
// };

export default BlogSlice.reducer;
