import { ListSubheader, styled, Theme } from "@mui/material";
import React from "react";

type NavGroup = {
  name?: string;
};

interface ItemType {
  item: NavGroup;
}

const NavGroup = ({ item }: ItemType) => {
  const ListSubheaderStyle = styled((props: Theme | any) => (
    <ListSubheader disableSticky {...props} />
  ))(({ theme }) => ({
    ...theme.typography.overline,
    fontWeight: "700",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(0),
    color: "text.Primary",
    lineHeight: "26px",
    padding: "3px 12px",
    marginLeft: "-10px",
  }));

  return <ListSubheaderStyle>{item?.name}</ListSubheaderStyle>;
};

export default NavGroup;
