import React from "react";
import {
  Box,
  Typography,
  Container,
  useTheme,
  Stack,
  IconButton,
} from "@mui/material";
import { BoxNewsletterStyled } from "./style";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import NewsletterForm from "./NewsletterForm";
import { dispatch } from "@/store/store/Store";
import { alertOpen, loadingOff, loadingOn } from "@/store/reducers/CoreSlice";
import { fetchPostNewsletter } from "@/store/reducers/MemberSlice";
import PinterestIcon from "@mui/icons-material/Pinterest";

const Newsletter = () => {
  const theme = useTheme();

  const onSubmitForm = async (formData: any) => {
    dispatch(loadingOn);
    await fetchPostNewsletter(
      formData,
      (res: any) => {
        dispatch(
          alertOpen({
            type: "SUCCESS",
            local: "",
            message: res.data.message,
            button: false,
            ssr: false,
          })
        );
      },
      (err: any) => {
        dispatch(
          alertOpen({
            type: "ERROR",
            local: "",
            message:
              err.message ??
              "Ocorreu um erro em nossos servidores! Por favor tente novamente!",
            button: false,
            ssr: false,
          })
        );
      }
    );
    dispatch(loadingOff);
  };

  return (
    <Box
      bgcolor={theme.palette.primary.extraLight}
      style={{
        border: "1px 0 1px 0 solid",
        borderColor: theme.palette.primary.main,
      }}
    >
      <Container maxWidth={"lg"}>
        <BoxNewsletterStyled paddingY={5}>
          <Box>
            <Typography
              variant="body1"
              fontSize={"1rem"}
              fontWeight={700}
              textAlign={"center"}
              color={"black"}
            >
              ASSINE NOSSA NEWSLETTER
            </Typography>
            <NewsletterForm onSubmitForm={onSubmitForm} />
          </Box>
          <Stack display="flex" direction="column" alignItems={"center"}>
            <Typography
              variant="body1"
              fontSize={"1rem"}
              fontWeight={700}
              textAlign={"center"}
              color={"black"}
            >
              NOSSAS REDES SOCIAIS
            </Typography>
            <Box>
              <IconButton
                color="primary"
                href="https://www.instagram.com/horadaatividadeoficial/"
                target="_blank"
                aria-label="InstagramIcon"
              >
                <InstagramIcon fontSize="large" />
              </IconButton>

              <IconButton
                color="primary"
                href="https://facebook.com/horadaatividade.com.br"
                target="_blank"
                aria-label="FacebookIcon"
              >
                <FacebookIcon fontSize="large" />
              </IconButton>

              <IconButton
                color="primary"
                href="https://www.youtube.com/@horadaatividadeoficial"
                target="_blank"
                aria-label="YouTubeIcon"
              >
                <YouTubeIcon fontSize="large" />
              </IconButton>

              <IconButton
                color="primary"
                href="https://www.pinterest.com/horadaatividadeoficial"
                target="_blank"
                aria-label="PinterestIcon"
              >
                <PinterestIcon fontSize="large" />
              </IconButton>
            </Box>
            <Typography mt={2}>contato@horadaatividade.com.br</Typography>
          </Stack>
        </BoxNewsletterStyled>
      </Container>
    </Box>
  );
};

export default Newsletter;
