import React, { ReactNode, createContext, useEffect, useState } from "react";
import api from "@/services/axios";
import { dispatch } from "../store/Store";
import { saveMenu, saveUser } from "../reducers/UserSlice";
import { userData } from "../types/userTypes";

type AuthProviderProps = {
  children: ReactNode;
};

interface AuthContextTypes {
  authenticated: boolean;
  logging: boolean;
  signIn: any;
  signOut: () => void;
}

const AuthContext = createContext<AuthContextTypes>(null!);

const AuthProvider = ({ children }: AuthProviderProps) => {
  const [authenticated, setAuthenticated] = useState(false);
  const [logging, setLogging] = useState(true);

  useEffect(() => {
    const getLogin = async () => {
      const token = localStorage.getItem("token");
      const result = await valUser();

      if (token && result) {
        api.defaults.headers.Authorization = `Bearer ${token}`;
        signIn(result.data, false);
      }

      setLogging(false);
    };

    getLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const valUser = async () => {
    const valueToken = localStorage.getItem("token");

    const headers = {
      headers: {
        Authorization: "Bearer " + valueToken,
      },
    };

    try {
      if (!valueToken) {
        throw Error;
      }
      const user = await api.get("/auth/val-token", headers);
      return user;
    } catch (error) {
      setAuthenticated(false);
      localStorage.removeItem("token");
      api.defaults.headers.Authorization = null;
      return false;
    }
  };

  async function signIn(data: any, save: boolean) {
    if (save) {
      localStorage.setItem("token", data.token);
      if (typeof localStorage !== "undefined") {
        api.defaults.headers.Authorization = `Bearer ${data.token}`;
      }
    }
    setAuthenticated(true);
    dispatch(saveUser(data.user));
    dispatch(saveMenu(data.menu));
  }

  function signOut() {
    localStorage.removeItem("token");
    setAuthenticated(false);
    dispatch(saveMenu([]));
    dispatch(saveUser({} as userData));

    api.defaults.headers.Authorization = null;
  }

  return (
    <AuthContext.Provider
      value={{
        authenticated,
        logging,
        signIn,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuthContext(): AuthContextTypes {
  return React.useContext(AuthContext);
}

export { AuthContext, AuthProvider, useAuthContext };
