import { toggleMobileSidebar } from "@/store/reducers/CustomizerSlice";
import { useDispatch } from "@/store/store/Store";
import LogoutIcon from "@mui/icons-material/Logout";
import { logoutUser } from "@/store/reducers/AuthSlice";
// import { ButtonIconLeft } from "@/components/_Shared/Buttons/ButtonIconLeft";
import { useRouter } from "next/router";
import { useAuthContext } from "@/store/auth/AuthContext";
import { Button } from "@mui/material";

interface LogoutButtonProps {
  // shortTitle?: boolean;
  fullWidth?: boolean;
  toggle?: boolean;
}

const LogoutButton = ({ toggle }: LogoutButtonProps) => {
  const router = useRouter();
  const dispatch = useDispatch();

  const { signOut } = useAuthContext();

  return (
    <Button
      variant="contained"
      fullWidth
      color="primary"
      // startIcon={<LogoutIcon />}
      onClick={() => {
        signOut();
        toggle && dispatch(toggleMobileSidebar());
      }}
    >
      Sair
    </Button>
  );
};

export default LogoutButton;
