import { styled } from "@mui/material/styles";
import { Box, Stack } from "@mui/material";
import { palette } from "@/styles/ThemeMui";

export const BoxNewsletterStyled = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;

  @media (min-width: 900px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  /* @media (min-width: 1200px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  } */
`;

export const StackNewsletterFormStyled = styled(Stack)`
  flex-direction: column;

  @media (min-width: 600px) {
    flex-direction: row;
  }
`;
