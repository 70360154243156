import { Container, Hidden, IconButton, Stack } from "@mui/material";
import { AppBarStyled, ToolbarStyled } from "./style";
import Logo from "@/sections/_Layout/Header/logo";
import User from "@/sections/_Layout/Header/User";
import Search from "@/sections/_Layout/Header/Search";
import Cart from "@/sections/_Layout/Header/Cart";
import { dispatch } from "@/store/store/Store";
import DesktopMainNavList from "@/components/navigation/NavLists/DesktopMainNavList/DesktopMainNavList";
import MenuIcon from "@mui/icons-material/Menu";
import { toggleMobileSidebar } from "@/store/reducers/CustomizerSlice";

const Header = () => {
  return (
    <AppBarStyled position="sticky" color="default">
      <ToolbarStyled>
        <Container maxWidth="lg">
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            style={{ width: "100%" }}
            alignContent={"center"}
          >
            <Hidden mdUp>
              <IconButton
                color="inherit"
                aria-label="menu"
                // onClick={() => handleDrawerOpen()}
                onClick={() => {
                  dispatch(toggleMobileSidebar());
                }}
              >
                <MenuIcon fontSize="medium" />
              </IconButton>
            </Hidden>
            <Logo />
            <DesktopMainNavList />
            <Stack direction={"row"} alignItems={"center"}>
              <Search />
              {/* <Cart /> */}
              <User />
            </Stack>
          </Stack>
        </Container>
      </ToolbarStyled>
    </AppBarStyled>
  );
};

export default Header;
