import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";

export const palette = {
  primary: {
    extraLight: "#ffedef",
    light: "#ffdbe0",
    main: "#FFB2BF",
    dark: "#E58293",
    extraDark: "#CC7483",
    contrastText: "#000",
  },
  secondary: {
    extraLight: "#d8f2f1",
    light: "#afe5e3",
    main: "#83d7d5",
    dark: "#6db0ae",
    extraDark: "#578a88",
    contrastText: "#000",
  },
  terciary: {
    extraLight: "#ffefda",
    light: "#ffcf90",
    main: "#FFBF4E",
    contrastText: "#000",
  },
  white: {
    main: "#ffffff",
    contrastText: "#555555",
  },
  neutral: {
    extraLight: "#f0f0f0",
    light: "#BFBFBF",
    main: "#D2D2D2",
    dark: "#4F4949",
    contrastText: "#000",
  },
  black: {
    main: "#000",
    contrastText: "#fff",
  },
  dark: {
    main: "#192439",
    light: "#3b4356",
    dark: "#141a28",
    contrastText: "#fff",
  },
};

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xxs: true; // adds the `mobile` breakpoint
    xs: true; // removes the `xs` breakpoint
    sm: true;
    md: true;
    lg: true;
    xl: true;
  }

  interface PaletteColor {
    extraLight?: string;
  }

  interface SimplePaletteColorOptions {
    extraLight?: string;
  }
  interface Palette {
    white: Palette["primary"];
    neutral: Palette["primary"];
    black: Palette["primary"];
    terciary: Palette["secondary"];
    dark: Palette["secondary"];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    white?: PaletteOptions["primary"];
    neutral?: PaletteOptions["primary"];
    black?: PaletteOptions["primary"];
    terciary?: PaletteOptions["secondary"];
    dark?: PaletteOptions["secondary"];
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    white: true;
    neutral: true;
    black: true;
    terciary: true;
    dark: true;
  }
}
declare module "@mui/material/LinearProgress" {
  interface LinearProgressPropsColorOverrides {
    white: true;
    neutral: true;
    black: true;
    terciary: true;
    dark: true;
  }
}

declare module "@mui/material/TextField" {
  interface TextFieldPropsColorOverrides {
    white: true;
    neutral: true;
    black: true;
    terciary: true;
    dark: true;
  }
}

declare module "@mui/material/IconButton" {
  interface IconButtonPropsColorOverrides {
    white: true;
    neutral: true;
    black: true;
    terciary: true;
    dark: true;
  }
}

declare module "@mui/material/Badge" {
  interface BadgePropsColorOverrides {
    white: true;
    neutral: true;
    black: true;
    terciary: true;
    dark: true;
  }
}

declare module "@mui/material/Chip" {
  interface ChipPropsColorOverrides {
    white: true;
    neutral: true;
    black: true;
    terciary: true;
    dark: true;
  }
}

declare module "@mui/material/ButtonGroup" {
  interface ButtonGroupPropsColorOverrides {
    white: true;
    neutral: true;
    black: true;
    terciary: true;
    dark: true;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsColorOverrides {
    white: true;
    neutral: true;
    black: true;
    terciary: true;
    dark: true;
  }
}

declare module "@mui/material/Chip" {
  interface ChipPropsColorOverrides {
    white: true;
    neutral: true;
    black: true;
    terciary: true;
    dark: true;
  }
}

const Theme = createTheme({
  palette,
  typography: {
    fontFamily: ["Nunito"].join(","),

    h1: {
      fontSize: "1.7rem", // Defina o tamanho de fonte desejado para h1
    },
    h2: {
      fontSize: "1.4rem", // Defina o tamanho de fonte desejado para h2
    },
    h3: {
      fontSize: "1.3rem", // Defina o tamanho de fonte desejado para h2
    },
    h4: {
      fontSize: "1.2rem", // Defina o tamanho de fonte desejado para h2
    },
    h5: {
      fontSize: "1.1rem", // Defina o tamanho de fonte desejado para h2
    },
    h6: {
      fontSize: "1.0rem", // Defina o tamanho de fonte desejado para h2
    },
    // Adicione outras variantes de Typography aqui
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 700,
          // borderRadius: 30,
          // fontSize: "1.2rem",
          // lineHeight: 1.5,
        },
      },
    },

    // MuiButtonGroup: {
    //   styleOverrides: {
    //     root: {
    //       borderRadius: 30,
    //       fontSize: "1.2rem",
    //       lineHeight: 1.5,
    //     },
    //   },
    // },

    MuiLink: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
          lineHeight: 1.5,
        },
      },
    },
  },

  breakpoints: {
    values: {
      xxs: 200,
      xs: 350,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1800,
    },
  },
});

interface ThemeType {
  children: any;
}

const ThemeMui: React.FC<ThemeType> = ({ children }) => {
  return <ThemeProvider theme={Theme}>{children}</ThemeProvider>;
};

export default ThemeMui;
