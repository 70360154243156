import React from "react";
import { Stack, InputBase, Typography, useTheme, Box } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import ChatIcon from "@mui/icons-material/Chat";
import PersonIcon from "@mui/icons-material/Person";
import SearchIcon from "@mui/icons-material/Search";

type InputIconType = {
  placeholder?: string;
  type:
    | "email"
    | "password"
    | "username"
    | "name"
    | "description"
    | "no-icon"
    | "search";
  name?: string;
  value?: unknown;
  onChange: (e: any) => void;
  color?: "primary" | "secondary";
  label?: string;
  autoComplete?: string;
  idLabel?: any;
  disabled?: boolean;
};

const InputIcon: React.FC<InputIconType> = ({
  idLabel,
  placeholder,
  type,
  name,
  value,
  onChange,
  color,
  label,
  autoComplete,
  disabled,
}) => {
  const theme = useTheme();
  return (
    <>
      <Box>
        {label && (
          <Typography
            variant="body2"
            // color={color ? color : "primary"}
            style={{ paddingBottom: "3px" }}
            fontWeight={600}
            id={idLabel}
          >
            {label}
          </Typography>
        )}
        <Stack
          direction={"row"}
          alignItems="center"
          style={{
            display: "flex",
            height: "45px",
            border: "1px solid",
            borderColor:
              color == "secondary"
                ? theme.palette.secondary.main
                : theme.palette.primary.main,
            borderRadius: "10px",
            padding: "0px 8px",
          }}
        >
          {type == "email" && (
            <EmailIcon
              style={{
                color:
                  color == "secondary"
                    ? theme.palette.secondary.main
                    : theme.palette.primary.main,
                marginRight: "8px",
                width: "20px",
                height: "20px",
              }}
            />
          )}
          {type == "password" && (
            <LockIcon
              style={{
                color:
                  color == "secondary"
                    ? theme.palette.secondary.main
                    : theme.palette.primary.main,
                marginRight: "8px",
                width: "22px",
                height: "22px",
              }}
            />
          )}

          {type == "name" && (
            <PersonIcon
              style={{
                color:
                  color == "secondary"
                    ? theme.palette.secondary.main
                    : theme.palette.primary.main,
                marginRight: "8px",
                width: "22px",
                height: "22px",
              }}
            />
          )}

          {type == "search" && (
            <SearchIcon
              style={{
                color:
                  color == "secondary"
                    ? theme.palette.secondary.main
                    : theme.palette.primary.main,
                marginRight: "8px",
                width: "22px",
                height: "22px",
              }}
            />
          )}

          <InputBase
            fullWidth
            placeholder={placeholder}
            id={name}
            name={name}
            value={value}
            type={type == "password" ? "password" : "text"}
            onChange={onChange}
            autoComplete={autoComplete ? autoComplete : "off"}
            disabled={disabled}
          />
        </Stack>
      </Box>
    </>
  );
};

export default InputIcon;
