import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";

interface CloseButtonProps {
  onClick: any;
  fullWidth?: boolean;
  toggle?: boolean;
}

const CloseButton = ({ onClick }: CloseButtonProps) => {
  return (
    <IconButton size="small" sx={{ bgcolor: "grey.100" }} onClick={onClick}>
      <CloseIcon fontSize={"small"} style={{ color: "black" }} />
    </IconButton>
  );
};

export default CloseButton;
