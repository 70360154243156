import { useRouter } from "next/router";
import { Hidden } from "@mui/material";
import Image from "next/image";
import Link from "next/link";
import styled from "@emotion/styled";

const CustomH1 = styled.h1`
  span {
    display: none;
  }
`;

const Logo = () => {
  const router = useRouter();
  const isHomePage = router.pathname === "/";

  return (
    <>
      <Link href="https://horadaatividade.com.br" title="Hora da Atividade">
        {isHomePage && (
          // <Link href="https://horadaatividade.com.br" title="Hora da Atividade">
          <CustomH1>
            <span>Hora da Atividade</span>
          </CustomH1>
          // </Link>
        )}
        <Hidden smDown>
          <Image
            src="/logo.png"
            width={267}
            height={45}
            alt="Hora da Atividade"
            priority
            quality={100}
          />
        </Hidden>
        <Hidden xsDown smUp>
          <Image
            src="/logo.png"
            width={210}
            height={35}
            alt="Hora da Atividade"
            priority
            quality={100}
            style={{ marginTop: "6px" }}
          />
        </Hidden>
        <Hidden xsUp>
          <Image
            src="/logo-no-icon.png"
            width={128}
            height={36}
            alt="Hora da Atividade"
            priority
            quality={100}
            style={{ marginTop: "6px" }}
          />
        </Hidden>
      </Link>
    </>
  );
};

export default Logo;
