import { configureStore } from "@reduxjs/toolkit";

import AdminReducer from "../reducers/AdminSlice";
import AuthReducer from "../reducers/AuthSlice";
import BlogReducer from "../reducers/BlogSlice";
import CoreReducer from "../reducers/CoreSlice";
import StoreReducer from "../reducers/StoreSlice";
import GameReducer from "../reducers/GameSlice";
import UserReducer from "../reducers/UserSlice";
import CustomizerReducer from "../reducers/CustomizerSlice";

import { combineReducers } from "redux";
import {
  useDispatch as useAppDispatch,
  useSelector as useAppSelector,
  TypedUseSelectorHook,
} from "react-redux";

export const store = configureStore({
  reducer: {
    admin: AdminReducer,
    customizer: CustomizerReducer,
    blogReducer: BlogReducer,
    gameReducer: GameReducer,
    authReducer: AuthReducer,
    coreReducer: CoreReducer,
    storeReducer: StoreReducer,
    user: UserReducer,
  },
});

const rootReducer = combineReducers({
  admin: AdminReducer,
  customizer: CustomizerReducer,
  blogReducer: BlogReducer,
  gameReducer: GameReducer,
  authReducer: AuthReducer,
  coreReducer: CoreReducer,
  storeReducer: StoreReducer,
  user: UserReducer,
});

export type AppState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export const { dispatch } = store;
export const useDispatch = () => useAppDispatch<AppDispatch>();
export const useSelector: TypedUseSelectorHook<AppState> = useAppSelector;

export default store;
