import { useState } from "react";
import {
  IconButton,
  Dialog,
  DialogContent,
  Stack,
  Divider,
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  Theme,
  Button,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CancelIcon from "@mui/icons-material/Cancel";
import InputIcon from "@/components/Form/InputIcon";
import { dispatch } from "@/store/store/Store";
import { getPosts, saveSearch } from "@/store/reducers/BlogSlice";
import { useRouter } from "next/router";

interface menuType {
  title: string;
  id: string;
  subheader: string;
  children: menuType[];
  href: string;
}

const Search = () => {
  const theme = useTheme();
  const [modalOpen, setModalOpen] = useState(false);
  const [error, setError] = useState(false);
  const [search, setSearch] = useState("");
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  const router = useRouter();

  const handleDrawerClose2 = () => {
    setModalOpen(false);
  };

  const onClickButton = () => {
    if (search != "") {
      setError(false);
      setModalOpen(false);
      dispatch(getPosts([]));
      dispatch(saveSearch(search));
      router.push("/search");
    } else {
      setError(true);
    }
  };

  const onClickOpenModal = () => {
    setSearch("");
    setModalOpen(true);
  };

  return (
    <>
      <IconButton
        aria-label="Abrir menu de pesquisa"
        color="inherit"
        aria-haspopup="dialog"
        onClick={() => onClickOpenModal()}
      >
        <SearchIcon
          aria-hidden="true"
          style={{
            width: lgUp ? "30px" : "25px",
            height: lgUp ? "30px" : "25px",
          }}
        />
      </IconButton>
      <Dialog
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        fullWidth
        PaperProps={{
          sx: {
            position: "fixed",
            top: 30,
            m: 0,
            left: smDown ? 12 : "undefined",
            width: "calc(100vw - 24px)",
            maxWidth: "400px",
          },
        }}
      >
        <DialogContent style={{ padding: "5px" }}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent={"space-between"}
            alignItems="center"
          >
            <Typography
              id="search-dialog-title"
              variant="body1"
              fontSize={"1rem"}
              color="black"
              fontWeight={700}
              mt={1}
              pl={2}
            >
              PESQUISA
            </Typography>
            <IconButton size="large" onClick={handleDrawerClose2}>
              <CancelIcon
                fontSize="large"
                style={{ color: theme.palette.primary.main }}
              />
            </IconButton>
          </Stack>
        </DialogContent>
        <Divider />
        <Box p={2}>
          <InputIcon
            placeholder="Digite aqui sua pesquisa"
            type="search"
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            name="search"
          />
          {error && (
            <Typography variant={"caption"} color={"error"}>
              *Digite o que deseja procurar
            </Typography>
          )}

          <Button
            color="primary"
            variant="contained"
            style={{ marginTop: "20px" }}
            onClick={() => onClickButton()}
            id="search-menu"
          >
            Procurar
          </Button>
        </Box>
      </Dialog>
    </>
  );
};

export default Search;
