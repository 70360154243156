import React from "react";
import { css } from "@emotion/react";

const globalStyles = css`
  body {
    padding: 0;
    margin: 0;
    /* background: #f8f8f8; */
    overflow: -moz-scrollbars-vertical;
    overflow-y: scroll;
    color: #000000;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  * {
    box-sizing: border-box;
  }
`;

export default globalStyles;
