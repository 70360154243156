import react, { useEffect, useState } from "react";
import { Container, Stack } from "@mui/material";
import { AppBarStyled, ToolbarStyled } from "./style";
import MenuLateral from "@/sections/_Layout/Menu/MenuLateral/MenuLateral";
import LogoLinks from "@/sections/_Layout/Header/LogoLinks";
import User from "@/sections/_Layout/Header/User";
import Search from "@/sections/_Layout/Header/Search";
import Cart from "@/sections/_Layout/Header/Cart";
import { MenuType } from "@/store/types/coreTypes";
import { AppState, dispatch, useSelector } from "@/store/store/Store";

const HeaderLinks = () => {
  return (
    <AppBarStyled position="sticky" color="default">
      <ToolbarStyled>
        <Container maxWidth="sm">
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            style={{ width: "100%" }}
            alignContent={"center"}
          >
            <LogoLinks />
          </Stack>
        </Container>
      </ToolbarStyled>
    </AppBarStyled>
  );
};

export default HeaderLinks;
