import { toggleMobileSidebar } from "@/store/reducers/CustomizerSlice";
import { AppState, useDispatch, useSelector } from "@/store/store/Store";
import {
  useMediaQuery,
  Box,
  Drawer,
  Theme,
  Divider,
  Stack,
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import { logoutUser } from "@/store/reducers/AuthSlice";

import CloseButton from "../NaviButtons/CloseButton/CloseButton";
import { MenuType } from "@/store/types/coreTypes";
import LogoutButton from "../NaviButtons/LogoutButton/LogoutButton";
import MenuUserInfoLateral from "@/sections/_Layout/Menu/MenuLateral/MenuUserInfoLateral";
import MenuListLateralMain from "@/sections/_Layout/Menu/MenuLateral/MenuListLateralMain";
import MobileMainNavList from "../NavLists/MobileNavList/MobileNavList";
import NavGroup from "../NavLists/NavGroup";

interface MobileAdminNavigationProps {
  groupMenu: string;
}

const MobileAdminNavigation: React.FC<MobileAdminNavigationProps> = ({
  groupMenu,
}) => {
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
  const customizer = useSelector((state: AppState) => state.customizer);
  const dispatch = useDispatch();

  const { menu } = useSelector((state: AppState) => state.user);

  const menuItems = menu.find((item: MenuType) => item.name === groupMenu);
  const menuItem = menuItems?.children ?? [];

  const mainMenu: MenuType[] = useSelector(
    (state: AppState) => state.coreReducer.menu
  );

  if (mdUp) {
    return;
  }

  return (
    <Drawer
      anchor="left"
      open={customizer.isMobileSidebar}
      onClose={() => dispatch(toggleMobileSidebar())}
      variant="temporary"
      PaperProps={{
        sx: {
          width: customizer.SidebarWidth,
          border: "0 !important",
          boxShadow: (theme) => theme.shadows[8],
        },
      }}
    >
      <Box p={2}>
        <Stack
          direction="row"
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <MenuUserInfoLateral handleDrawerClose={() => {}} />
          <CloseButton onClick={() => dispatch(toggleMobileSidebar())} />
        </Stack>
        <Divider sx={{ mb: 1, mt: 2 }} />

        <MobileMainNavList menu={menuItem} />
        <Box px={3}>
          <LogoutButton toggle />
        </Box>
        <Divider sx={{ mb: 2, mt: 3 }} />
        <NavGroup
          item={{
            name: "Menu do Site",
          }}
        />
        <MobileMainNavList menu={mainMenu} />
      </Box>
    </Drawer>
  );
};

export default MobileAdminNavigation;
