import React from "react";
import Link from "next/link";

// mui imports
import {
  ListItemIcon,
  ListItem,
  List,
  styled,
  ListItemText,
  useTheme,
  ListItemButton,
} from "@mui/material";
import { AppState, useSelector } from "@/store/store/Store";
import { GetIconNew } from "@/utils/GetIcon/GetIconNew";
import { useRouter } from "next/router";

type NavGroup = {
  id?: string;
  name?: string;
  icon?: any;
  href: string;
};

interface ItemType {
  item: NavGroup;
  onClick?: React.MouseEventHandler<HTMLElement>;
}

const NavMenu = ({ item, onClick }: ItemType) => {
  const customizer = useSelector((state: AppState) => state.customizer);
  const Icon: any = GetIconNew(item.icon, { fontSize: "1.1rem !important" });
  const theme = useTheme();
  const { pathname } = useRouter();

  const ListItemStyled2 = styled(ListItemButton)(() => ({
    width: "auto",
    padding: "5px 10px",
    position: "relative",
    flexGrow: "unset",
    gap: "5px",

    borderRadius: `${customizer.borderRadius}px`,
    whiteSpace: "nowrap",
    color: pathname.includes(item.href)
      ? "white"
      : theme.palette.text.secondary,
    backgroundColor: pathname.includes(item.href)
      ? theme.palette.primary.main
      : "",

    "&:hover": {
      backgroundColor: pathname.includes(item.href)
        ? theme.palette.primary.main
        : theme.palette.primary.light,
    },
    "&:hover > .SubNav": { display: "block" },
  }));

  const listItemProps: {
    component: any;
    href?: string;
    target?: any;
    to?: any;
  } = {
    component: Link,
    to: item?.href,
    href: item?.href,
    target: "",
  };

  return (
    <List component="li" disablePadding key={item.id}>
      <ListItemStyled2 {...listItemProps} onClick={onClick}>
        <ListItemIcon
          sx={{
            minWidth: "auto",
            p: "3px 0",
            color: "inherit",
          }}
        >
          {Icon}
        </ListItemIcon>
        <ListItemText>{item.name}</ListItemText>
      </ListItemStyled2>
    </List>
  );
};

export default NavMenu;
