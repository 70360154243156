import React from "react";
import {
  Box,
  ListItemIcon,
  MenuList,
  ListItemText,
  Collapse,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useRouter } from "next/router";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { MenuItemStyled, MenuSubitemStyled } from "./style";
import { GetIconNew } from "@/utils/GetIcon/GetIconNew";
import { toggleMobileSidebar } from "@/store/reducers/CustomizerSlice";
import { dispatch } from "@/store/store/Store";

interface MobileNavItemType {
  item: any;
  subitem?: boolean;
}

const MobileMainNavItem = ({ item, subitem }: MobileNavItemType) => {
  const [open, setOpen] = React.useState(false);
  const router = useRouter();

  const getSelected = (href: any) => {
    const pathAfterDomain = router.asPath;
    if (pathAfterDomain.includes(href)) {
      return true;
    }
    return false;
  };

  const getHref = (href: any, showCategories: boolean) => {
    return `${href}`;
  };

  const goTo = (href: any) => {
    dispatch(toggleMobileSidebar());
    router.push(href);
  };

  return (
    <>
      {!subitem && (
        <MenuItemStyled selected={getSelected(item.href)}>
          <ListItemIcon
            onClick={() => {
              item.href && goTo(item.href);
              !item.href && item.children?.length > 0 && setOpen(true);
            }}
            style={{ minWidth: "30px" }}
          >
            {GetIconNew(item.icon)}
          </ListItemIcon>
          <ListItemText
            onClick={() => {
              item.href && goTo(getHref(item.href, item.showCategories));
              !item.href && item.children?.length > 0 && setOpen(true);
            }}
          >
            {item.name}
          </ListItemText>
          {item.children?.length > 0 && (
            <ExpandMoreIcon onClick={() => setOpen(!open)} />
          )}
        </MenuItemStyled>
      )}
      {subitem && (
        <MenuSubitemStyled selected={getSelected(item.href)}>
          <ListItemIcon
            onClick={() => goTo(item.href)}
            style={{ minWidth: "15px" }}
          >
            <RadioButtonUncheckedIcon style={{ width: "5px", height: "5px" }} />
          </ListItemIcon>
          <ListItemText onClick={() => goTo(item.href)}>
            {item.name}
          </ListItemText>
          {item.children?.length > 0 && (
            <ExpandMoreIcon onClick={() => setOpen(!open)} />
          )}
        </MenuSubitemStyled>
      )}

      {item.children && item.children?.length > 0 && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box pl={1}>
            <MenuList>
              {item.children.map(
                (
                  data: { children: any },
                  index: React.Key | null | undefined
                ) => (
                  <MobileMainNavItem key={index} item={data} subitem />
                )
              )}
            </MenuList>
          </Box>
        </Collapse>
      )}
    </>
  );
};

export default MobileMainNavItem;
