import { createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "../store/Store";
import api from "@/services/axios";

interface StateType {
  menu: any;
}

const initialState = {
  menu: "",
} as StateType;

const AdminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    // hasError(state: StateType, action) {
    //   state.error = action.payload;
    // },
  },
});

export const {} = AdminSlice.actions;

export const fetchParametersCrud = async (
  table: string,
  action: string,
  callback: any,
  errocallback: any
) => {
  await api
    .get(`admin/crud/parameters/${table}/${action}`)
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      if (errocallback != null) {
        errocallback(err);
      }
    });
};

export default AdminSlice.reducer;
