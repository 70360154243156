import React from "react";
import Header from "@/sections/_Layout/Header/Header";
import styled from "@emotion/styled";
import { Box } from "@mui/material";
import MobileAdminNavigation from "@/components/navigation/MobileNavigation/MobileAdminNavigation";
import DesktopNavigation from "@/components/navigation/DesktopNavigation/DesktopNavigation";
import PrivateRoute from "./PrivateRoute";

interface Props {
  children: React.ReactNode;
}

const ContainerStyled = styled("div")(() => ({
  display: "flex",
  minHeight: "100vh",
  width: "100%",
  flexDirection: "column",
}));

const ChildrenStyled = styled(Box)(({ theme }) => ({
  flex: "1",
  paddingBottom: "20px",
}));

const LayoutAdmin: React.FC<Props> = ({ children }) => {
  return (
    <>
      <PrivateRoute>
        <ContainerStyled>
          <Header />
          <MobileAdminNavigation groupMenu={"Admin"} />
          <DesktopNavigation groupMenu={"Admin"} />
          <ChildrenStyled>{children}</ChildrenStyled>
        </ContainerStyled>
      </PrivateRoute>
    </>
  );
};

export default LayoutAdmin;
