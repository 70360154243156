import * as yup from "yup";

yup.setLocale({
  mixed: {
    required: "Campo obrigatório",
    notType: "Insira um valor válido",
  },
  string: {
    email: "E-mail inválido",
    min: ({ min }) => `Deve ter pelo menos ${min} caracteres`,
  },
  number: {
    positive: "Insira um valor maior que 0,00",
  },
});

export default yup;
