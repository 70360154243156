import React from "react";
import WallpaperIcon from "@mui/icons-material/Wallpaper";
import StorefrontIcon from "@mui/icons-material/Storefront";
import LuggageIcon from "@mui/icons-material/LuggageOutlined";
import PortraitIcon from "@mui/icons-material/Portrait";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import PaletteIcon from "@mui/icons-material/Palette";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotionsOutlined";
import AttractionsIcon from "@mui/icons-material/Attractions";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import ArticleIcon from "@mui/icons-material/ArticleOutlined";
import CategoryIcon from "@mui/icons-material/CategoryOutlined";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import CollectionsIcon from "@mui/icons-material/Collections";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import ExtensionIcon from "@mui/icons-material/Extension";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import SmartDisplayOutlinedIcon from "@mui/icons-material/SmartDisplayOutlined";
import ManageSearchOutlinedIcon from "@mui/icons-material/ManageSearchOutlined";
import ExtensionOutlinedIcon from "@mui/icons-material/ExtensionOutlined";
import HubOutlinedIcon from "@mui/icons-material/HubOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import StorefrontOutlinedIcon from "@mui/icons-material/StorefrontOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import QuizOutlinedIcon from "@mui/icons-material/QuizOutlined";
import ContactSupportOutlinedIcon from "@mui/icons-material/ContactSupportOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import SupervisorAccountOutlinedIcon from "@mui/icons-material/SupervisorAccountOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import BallotOutlinedIcon from "@mui/icons-material/BallotOutlined";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import LibraryAddOutlinedIcon from "@mui/icons-material/LibraryAddOutlined";
// import FeedOutlinedIcon from "@mui/icons-material/FeedOutlined";
import OndemandVideoOutlinedIcon from "@mui/icons-material/OndemandVideoOutlined";
import RocketLaunchOutlinedIcon from "@mui/icons-material/RocketLaunchOutlined";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import TerminalOutlinedIcon from "@mui/icons-material/TerminalOutlined";
import DatasetOutlinedIcon from "@mui/icons-material/DatasetOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import MenuOpenOutlinedIcon from "@mui/icons-material/MenuOpenOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";
import VpnLockOutlinedIcon from "@mui/icons-material/VpnLockOutlined";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PersonIcon from "@mui/icons-material/Person";
import ConfirmationNumberOutlinedIcon from "@mui/icons-material/ConfirmationNumberOutlined";
import SavingsOutlinedIcon from "@mui/icons-material/SavingsOutlined";
import VideoLibraryOutlinedIcon from "@mui/icons-material/VideoLibraryOutlined";
import AppsOutlinedIcon from "@mui/icons-material/AppsOutlined";

export const GetIconNew = (icon: any, sx?: any) => {
  switch (icon) {
    case "AppsOutlinedIcon":
      return <AppsOutlinedIcon sx={sx} />;
    case "VideoLibraryOutlinedIcon":
      return <VideoLibraryOutlinedIcon sx={sx} />;
    case "SavingsOutlinedIcon":
      return <SavingsOutlinedIcon sx={sx} />;
    case "ConfirmationNumberOutlinedIcon":
      return <ConfirmationNumberOutlinedIcon sx={sx} />;
    case "WallpaperIcon":
      return <WallpaperIcon sx={sx} />;
    case "StorefrontIcon":
      return <StorefrontIcon sx={sx} />;
    case "LuggageIcon":
      return <LuggageIcon sx={sx} />;
    case "PortraitIcon":
      return <PortraitIcon sx={sx} />;
    case "ArrowCircleDown":
      return <ArrowCircleDownIcon sx={sx} />;
    case "ReceiptLongIcon":
      return <ReceiptLongIcon sx={sx} />;
    case "PaletteIcon":
      return <PaletteIcon sx={sx} />;
    case "EmojiEmotionsIcon":
      return <EmojiEmotionsIcon sx={sx} />;
    case "AttractionsIcon":
      return <AttractionsIcon sx={sx} />;
    case "PlaylistAddIcon":
      return <PlaylistAddIcon sx={sx} />;
    case "ArticleIcon":
      return <ArticleIcon sx={sx} />;
    case "CategoryIcon":
      return <CategoryIcon sx={sx} />;
    case "PictureAsPdfIcon":
      return <PictureAsPdfIcon sx={sx} />;
    case "CollectionsIcon":
      return <CollectionsIcon sx={sx} />;
    case "AdsClickIcon":
      return <AdsClickIcon sx={sx} />;
    case "ExtensionIcon":
      return <ExtensionIcon sx={sx} />;
    case "HomeOutlinedIcon":
      return <HomeOutlinedIcon sx={sx} />;
    case "DashboardOutlinedIcon":
      return <DashboardOutlinedIcon sx={sx} />;
    case "CalendarMonthOutlinedIcon":
      return <CalendarMonthOutlinedIcon sx={sx} />;
    case "SchoolOutlinedIcon":
      return <SchoolOutlinedIcon sx={sx} />;
    case "SmartDisplayOutlinedIcon":
      return <SmartDisplayOutlinedIcon sx={sx} />;
    case "ManageSearchOutlinedIcon":
      return <ManageSearchOutlinedIcon sx={sx} />;
    case "ExtensionOutlinedIcon":
      return <ExtensionOutlinedIcon sx={sx} />;
    case "HubOutlinedIcon":
      return <HubOutlinedIcon sx={sx} />;
    case "ShoppingCartOutlinedIcon":
      return <ShoppingCartOutlinedIcon sx={sx} />;
    case "StorefrontOutlinedIcon":
      return <StorefrontOutlinedIcon sx={sx} />;
    case "PeopleAltOutlinedIcon":
      return <PeopleAltOutlinedIcon sx={sx} />;
    case "QuizOutlinedIcon":
      return <QuizOutlinedIcon sx={sx} />;
    case "ContactSupportOutlinedIcon":
      return <ContactSupportOutlinedIcon sx={sx} />;
    case "HelpOutlineOutlinedIcon":
      return <HelpOutlineOutlinedIcon sx={sx} />;
    case "SupervisorAccountOutlinedIcon":
      return <SupervisorAccountOutlinedIcon sx={sx} />;
    case "CheckCircleOutlineOutlinedIcon":
      return <CheckCircleOutlineOutlinedIcon sx={sx} />;
    case "CheckBoxOutlinedIcon":
      return <CheckBoxOutlinedIcon sx={sx} />;
    case "BallotOutlinedIcon":
      return <BallotOutlinedIcon sx={sx} />;
    case "PaidOutlinedIcon":
      return <PaidOutlinedIcon sx={sx} />;
    case "LibraryAddOutlinedIcon":
      return <LibraryAddOutlinedIcon sx={sx} />;
    case "OndemandVideoOutlinedIcon":
      return <OndemandVideoOutlinedIcon sx={sx} />;
    case "RocketLaunchOutlinedIcon":
      return <RocketLaunchOutlinedIcon sx={sx} />;
    case "NotificationsNoneOutlinedIcon":
      return <NotificationsNoneOutlinedIcon sx={sx} />;
    case "StarBorderOutlinedIcon":
      return <StarBorderOutlinedIcon sx={sx} />;
    case "FolderOutlinedIcon":
      return <FolderOutlinedIcon sx={sx} />;
    case "DeleteOutlineOutlinedIcon":
      return <DeleteOutlineOutlinedIcon sx={sx} />;
    case "TerminalOutlinedIcon":
      return <TerminalOutlinedIcon sx={sx} />;
    case "DatasetOutlinedIcon":
      return <DatasetOutlinedIcon sx={sx} />;
    case "DescriptionOutlinedIcon":
      return <DescriptionOutlinedIcon sx={sx} />;
    case "MenuOpenOutlinedIcon":
      return <MenuOpenOutlinedIcon sx={sx} />;
    case "ArticleOutlinedIcon":
      return <ArticleOutlinedIcon sx={sx} />;
    case "PostAddOutlinedIcon":
      return <PostAddOutlinedIcon sx={sx} />;
    case "MenuOutlinedIcon":
      return <MenuOutlinedIcon sx={sx} />;
    case "FactCheckOutlinedIcon":
      return <FactCheckOutlinedIcon sx={sx} />;
    case "AssignmentIndOutlinedIcon":
      return <AssignmentIndOutlinedIcon sx={sx} />;
    case "VpnLockOutlinedIcon":
      return <VpnLockOutlinedIcon sx={sx} />;
    case "AssignmentTurnedInOutlinedIcon":
      return <AssignmentTurnedInOutlinedIcon sx={sx} />;
    case "LinkOutlinedIcon":
      return <LinkOutlinedIcon sx={sx} />;
    case "PersonAddIcon":
      return <PersonAddIcon sx={sx} />;
    case "PersonIcon":
      return <PersonIcon sx={sx} />;
    default:
      return <WallpaperIcon sx={sx} />;
  }
};
