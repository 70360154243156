import { createSlice } from "@reduxjs/toolkit";
import { CategoryType, PostType } from "../types/blogTypes";
import api from "@/services/axios";

interface StateType {
  search: string;
  post: PostType;
  posts: PostType[];
  newPost: PostType;
  lastPosts: PostType[];
  categories: CategoryType[];
}

const initialState = {
  search: "",
  post: {} as PostType,
  posts: [],
  newPost: {} as PostType,
  lastPosts: [],
  categories: [],
} as StateType;

export const BlogSlice = createSlice({
  name: "Blog",
  initialState,
  reducers: {
    getPost: (state: StateType, action) => {
      state.post = action.payload;
    },

    getPosts: (state: StateType, action) => {
      state.posts = action.payload;
    },

    getLastPosts: (state: StateType, action) => {
      state.lastPosts = action.payload; ///alterar depois
    },

    getCategories: (state: StateType, action) => {
      state.categories = action.payload;
    },

    saveSearch: (state: StateType, action) => {
      state.search = action.payload;
    },
  },
});

export const { getPost, getPosts, getLastPosts, getCategories, saveSearch } =
  BlogSlice.actions;

export const fetchLastPostsStatic = async () => {
  try {
    const response = await api.get(`blog/last-posts`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchAllPostsStatic = async (page: number, limit: number) => {
  try {
    const response = await api.get(
      `blog/all-posts?page=${page}&limit=${limit}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

//////Não devolve erro. Trata de forma amigável
export const fetchLastPosts = async (callback: any, errocallback: any) => {
  await api
    .get(`blog/last-posts`)
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      if (errocallback != null) {
        errocallback(err);
      }
    });
};

export const fetchPostStatic = async (slug: string) => {
  try {
    // const response = await api.get(`blog/post`);
    const response = await api.get(`blog/post/${slug}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchCategoryPostsStatic = async (
  slug: string,
  page: number,
  limit: number
) => {
  try {
    const response = await api.get(
      `blog/posts/${slug}?page=${page}&limit=${limit}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchCategoryListStatic = async (slug: string) => {
  try {
    const response = await api.get(`blog/categories/${slug}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchCategoryListPageStatic = async () => {
  try {
    const response = await api.get(`blog/categories`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchSearch = async (
  search: any,
  tag: boolean,
  callback: any,
  errocallback: any
) => {
  await api
    .get(tag ? `search?searchTag=${search}` : `search?search=${search}`)
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      if (errocallback != null) {
        errocallback(err);
      }
    });
};

//////Devolve erro para abrir o modal
export const fetchCategoriesPathStatic = async () => {
  try {
    const response = await api.get(`blog/category-path`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchCategoriesPostPathStatic = async () => {
  try {
    const response = await api.get(`blog/category-post-path`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchPostsPathStatic = async () => {
  try {
    const response = await api.get(`blog/post-path`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchNews = async (callback: any, errocallback: any) => {
  await api
    .get("blog/news")
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      if (errocallback != null) {
        errocallback(err);
      }
    });
};

export const fetchHighlights = async (callback: any, errocallback: any) => {
  await api
    .get("blog/highlights")
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      if (errocallback != null) {
        errocallback(err);
      }
    });
};

export const fetchHighlightsStatic = async () => {
  try {
    const response = await api.get(`blog/highlights`);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchDownloads = async (callback: any, errocallback: any) => {
  await api
    .get("downloads", {
      headers: {
        "Cache-Control": "no-cache", // Adicione este cabeçalho para desativar o cache
      },
    })
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      if (errocallback != null) {
        errocallback(err);
      }
    });
};

export default BlogSlice.reducer;
