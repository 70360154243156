import { useEffect } from "react";
import { toggleMobileSidebar } from "@/store/reducers/CustomizerSlice";
import { AppState, useDispatch, useSelector } from "@/store/store/Store";
import {
  useMediaQuery,
  Box,
  Drawer,
  Theme,
  Divider,
  Button,
  Stack,
} from "@mui/material";

import NavGroup from "../NavLists/NavGroup";
import { useRouter } from "next/router";
import LoginButton from "../NaviButtons/LoginButton/LoginButton";
import CloseButton from "../NaviButtons/CloseButton/CloseButton";
import { useAuthContext } from "@/store/auth/AuthContext";
import Link from "next/link";
import LogoutButton from "../NaviButtons/LogoutButton/LogoutButton";
import MenuUserInfoLateral from "@/sections/_Layout/Menu/MenuLateral/MenuUserInfoLateral";
import MobileMainNavList from "../NavLists/MobileNavList/MobileNavList";

const MobileBlankNavigation = () => {
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
  const customizer = useSelector((state: AppState) => state.customizer);

  const { user } = useSelector((state: AppState) => state.user);
  const { menu } = useSelector((state: AppState) => state.coreReducer);

  const userPlatform = user?.userPlatform?.toLowerCase();

  const { authenticated } = useAuthContext();

  const router = useRouter();

  const dispatch = useDispatch();

  if (mdUp) {
    return;
  }
  return (
    <Drawer
      anchor="left"
      open={customizer.isMobileSidebar}
      onClose={() => dispatch(toggleMobileSidebar())}
      variant="temporary"
      PaperProps={{
        sx: {
          width: customizer.SidebarWidth,
          border: "0 !important",
          boxShadow: (theme) => theme.shadows[8],
        },
      }}
    >
      <Box p={2}>
        <Stack
          direction="row"
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <MenuUserInfoLateral
            handleDrawerClose={() => dispatch(toggleMobileSidebar())}
          />
          <CloseButton onClick={() => dispatch(toggleMobileSidebar())} />
        </Stack>
        <Divider sx={{ mb: 1, mt: 2 }} />
        <MobileMainNavList menu={menu} />

        {/* <NavGroup
          item={{
            name: "Área do Usuário",
          }}
        /> */}
        {authenticated && (
          <>
            <Box mt={2} px={3}>
              <Link href={`/${userPlatform}/dashboard`}>
                <Button
                  variant="contained"
                  fullWidth
                  color="secondary"
                  // startIcon={<PlayCircleOutlineIcon />}
                  onClick={() => {
                    dispatch(toggleMobileSidebar());
                  }}
                >
                  Acessar
                </Button>
              </Link>
            </Box>

            <Box mt={1} px={3}>
              <LogoutButton fullWidth toggle />
            </Box>
          </>
        )}
        {/* {!authenticated && (
          <Box mt={2} px={3}>
            <LoginButton fullWidth toggle />
          </Box>
        )} */}
      </Box>
    </Drawer>
  );
};

export default MobileBlankNavigation;
