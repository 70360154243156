import React, { useEffect } from "react";
import { useAuthContext } from "@/store/auth/AuthContext";
import { useRouter } from "next/router";
import { dispatch } from "@/store/store/Store";

import styled from "@emotion/styled";
import LoadingModal from "@/components/Modal/LoadingModal";

interface Props {
  children: React.ReactNode;
}

const PrivateRoute: React.FC<Props> = ({ children }) => {
  const router = useRouter();

  const { authenticated, logging } = useAuthContext();

  const ContainerStyled = styled("div")(() => ({
    display: "flex",
    minHeight: "100vh",
    width: "100%",
    flexDirection: "column",
    backgroundColor: "white",
  }));

  useEffect(() => {
    if (!authenticated && !logging) {
      router.push("/auth/login");
    }
    if (authenticated && !logging) {
      const valueToken = localStorage.getItem("token");
      // const eventSource = new EventSource(
      //   `http://localhost:8080/sse?token=${valueToken}`
      // );

      // eventSource.onmessage = (event) => {
      //   const notificationData = JSON.parse(event.data);
      //   if (notificationData.event == "notification") {
      //     dispatch(fetchNotification());
      //   }
      // };

      // return () => {
      //   eventSource.close();
      // };
    }
  }, [authenticated, logging, router]);

  if (logging) {
    return (
      <>
        <ContainerStyled />
        <LoadingModal open={logging} />
      </>
    );
  }

  return <>{authenticated ? children : <ContainerStyled />}</>;
};

export default PrivateRoute;
