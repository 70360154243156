import React from "react";
import { Typography, useTheme } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import { PostType } from "@/store/types/blogTypes";
import { useRouter } from "next/router";
import Image from "next/image";
import { AppState, useSelector } from "@/store/store/Store";
import { scrollToTop } from "@/utils/ScrollToTop";

const FooterNews = () => {
  const router = useRouter();

  const lastPosts: PostType[] = useSelector(
    (state: AppState) => state.blogReducer.lastPosts
  );

  const theme = useTheme();

  const onClickPost = (slug: string) => {
    scrollToTop();
    router.push(`/blog/post/${slug}`);
  };

  return (
    <>
      <Typography variant="body1" fontSize={"1rem"} fontWeight={700} pl={2}>
        ARTIGOS RECENTES
      </Typography>
      <List sx={{ bgcolor: "background.paper" }}>
        {lastPosts &&
          lastPosts.length > 0 &&
          lastPosts.slice(0, 3).map((item: PostType, index: number) => (
            <ListItem
              alignItems="flex-start"
              key={index}
              onClick={() => onClickPost(item.slug)}
              style={{ cursor: "pointer" }}
            >
              <ListItemAvatar>
                <>
                  <Avatar
                    style={{
                      width: "70px",
                      height: "70px",
                      backgroundColor: theme.palette.secondary.extraLight,
                      position: "relative",
                      marginRight: "10px",
                    }}
                    variant="rounded"
                  >
                    <Image
                      src={item.thumb}
                      alt={item.altText}
                      sizes="40vw"
                      fill
                      style={{ objectFit: "cover" }}
                    />
                  </Avatar>
                </>
              </ListItemAvatar>
              <ListItemText
                primary={item.name}
                secondary={new Date(item.date).toLocaleDateString()}
              />
            </ListItem>
          ))}
      </List>
    </>
  );
};

export default FooterNews;
