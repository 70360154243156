import { useState } from "react";
import {
  IconButton,
  Avatar,
  useMediaQuery,
  Theme,
  Button,
  MenuItem,
  ListItemIcon,
  Divider,
  Menu,
  ListItemText,
  Typography,
} from "@mui/material";
import Logout from "@mui/icons-material/Logout";
import { useRouter } from "next/router";
import { useAuthContext } from "@/store/auth/AuthContext";
import { useSelector, AppState } from "@/store/store/Store";
import { GetIconNew } from "@/utils/GetIcon/GetIconNew";
import LockIcon from "@mui/icons-material/Lock";

const User = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { authenticated, signOut } = useAuthContext();
  const { user } = useSelector((state: AppState) => state.user);
  const userPlatform = user?.userPlatform?.toLowerCase();

  const router = useRouter();

  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));
  const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const goTo = (url: any) => {
    router.push(url);
  };

  const logOut = () => {
    signOut();
  };

  return (
    <>
      {smUp && authenticated && (
        <>
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Avatar
              // src={user?.picture}
              sx={{ width: lgUp ? 44 : 39, height: lgUp ? 44 : 39 }}
              // alt={user?.name}
            />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem
              onClick={() => goTo(`/${userPlatform}/dashboard`)}
              style={{ paddingRight: "20px" }}
            >
              <ListItemIcon>
                <LockIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText sx={{ marginLeft: "-10px" }}>
                Área do Usuário
              </ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem onClick={logOut} style={{ paddingRight: "20px" }}>
              <ListItemIcon>
                <Logout fontSize="small" color="error" />
              </ListItemIcon>
              <ListItemText sx={{ marginLeft: "-5px" }}>
                <Typography color="error"> Sair</Typography>
              </ListItemText>
            </MenuItem>
          </Menu>
        </>
      )}

      {/* {smUp && !authenticated && (
        <>
          <Button
            color="primary"
            variant="contained"
            size="small"
            style={{ marginLeft: 8 }}
            onClick={() => router.push("/auth/login")}
          >
            Entrar
          </Button>
        </>
      )} */}
    </>
  );
};

export default User;
