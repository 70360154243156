import { Schema } from "yup";

type FormData = {
  [key: string]: unknown;
};

type FormProps = {
  schema: Schema<FormData>;
  formData: FormData;
  onSubmitSuccess: () => void;
  onSubmitFail: (errors: { [key: string]: string }) => void;
};

export const handleSubmit = ({
  schema,
  formData,
  onSubmitSuccess,
  onSubmitFail,
}: FormProps) => {
  schema
    .validate(formData, { abortEarly: false })
    .then(() => {
      onSubmitSuccess();
    })
    .catch((validationErrors: { inner: any[] }) => {
      const errorsValidated: { [key: string]: string } =
        validationErrors.inner.reduce(
          (acc, error) => ({ ...acc, [error.path]: error.message }),
          {}
        );
      onSubmitFail(errorsValidated);
    });
};
