import styled from "@emotion/styled";
import { AppBar, Toolbar } from "@mui/material";

export const AppBarStyled = styled(AppBar)`
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  background-color: white;
  height: 64px;

  @media (min-width: 900px) {
    height: 80px;
  }
`;

export const ToolbarStyled = styled(Toolbar)`
  width: 100%;
  height: 100%;
  max-height: 64px;
  padding: 0px !important;

  @media (min-width: 900px) {
    max-height: 80px;
  }
`;
