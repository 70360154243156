import api from "@/services/axios";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  isAuthenticated: false,
  token: null,
  error: null,
  success: null,
};

const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginUserSuccess(state, action) {
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.isAuthenticated = true;
      state.error = null;
      state.success = null;
    },

    loginUserFailure(state, action) {
      state.user = null;
      state.isAuthenticated = false;
      state.error = action.payload;
      state.success = null;
    },

    logoutUser(state) {
      state.user = null;
      state.token = null;
      state.isAuthenticated = false;
      state.error = null;
      state.success = null;
    },

    subscriptionSuccess(state, action) {
      state.success = action.payload;
    },

    subscriptionFailure(state, action) {
      state.error = action.payload;
    },

    closeSuccess(state) {
      state.success = null;
    },
  },
});

export const {
  loginUserSuccess,
  loginUserFailure,
  logoutUser,
  subscriptionSuccess,
  subscriptionFailure,
  closeSuccess,
} = AuthSlice.actions;

export const login = async (
  credentials: any,
  callback: any,
  errocallback: any
) => {
  await api
    .get(`/login`, credentials)
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      if (errocallback != null) {
        errocallback(err);
      }
    });
};

export const postLogin = async (
  credentials: any,
  callback: any,
  errocallback: any
) => {
  await api
    .post(`/auth/login`, credentials)
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      if (errocallback != null) {
        errocallback(err);
      }
    });
};

export const postRecover = async (
  credentials: any,
  callback: any,
  errocallback: any
) => {
  await api
    .post(`/login/recover`, credentials)
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      if (errocallback != null) {
        errocallback(err);
      }
    });
};

export const postRegister = async (
  credentials: any,
  callback: any,
  errocallback: any
) => {
  await api
    .post(`/user`, credentials)
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      if (errocallback != null) {
        errocallback(err);
      }
    });
};

export const putUser = async (
  credentials: any,
  callback: any,
  errocallback: any
) => {
  await api
    .put(`/user`, credentials)
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      if (errocallback != null) {
        errocallback(err);
      }
    });
};

export const putPassword = async (
  credentials: any,
  callback: any,
  errocallback: any
) => {
  await api
    .put(`/user/password`, credentials)
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      if (errocallback != null) {
        errocallback(err);
      }
    });
};

export const putPasswordKey = async (
  key: any,
  config: any,
  callback: any,
  errocallback: any
) => {
  await api
    .put(`login/update-password/${key}`, config)
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      if (errocallback != null) {
        errocallback(err);
      }
    });
};

export default AuthSlice.reducer;
