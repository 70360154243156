import { MenuItem, styled, useTheme } from "@mui/material";

export const MenuItemStyled = styled(MenuItem)(({ theme }) => ({
  // height: "50px",
  color: "#000",
  paddingLeft: "0px",
  paddingRight: "0px",

  "&:hover": {
    backgroundColor: theme.palette.primary.light,
    // color: theme.palette.white.main,
  },

  // "&:hover .MuiSvgIcon-root": {
  //   color: theme.palette.white.main,
  // },

  "&.Mui-selected": {
    backgroundColor: theme.palette.secondary.extraLight,
  },

  "&.Mui-selected:hover": {
    backgroundColor: theme.palette.primary.light,
    // color: theme.palette.white.main,
  },

  ".MuiSvgIcon-root": {
    color: "#000",
  },
}));
