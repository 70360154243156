import { useState, useEffect } from "react";
import { Box, Container, Grid } from "@mui/material";
import FooterCopyright from "@/sections/_Layout/Footer/FooterCopyright";
import FooterAbout from "@/sections/_Layout/Footer/FooterAbout";

import FooterNews from "@/sections/_Layout/Footer/FooterNews";
import { ContainerWhiteBox } from "@/styles/container";
import FooterLinks from "./FooterLinks";
import { AppState, useSelector } from "@/store/store/Store";
import { MenuType } from "@/store/types/coreTypes";

const Footer = () => {
  const menu: MenuType[] = useSelector(
    (state: AppState) => state.coreReducer.menu
  );

  return (
    <ContainerWhiteBox>
      <Container maxWidth="lg">
        <Grid container spacing={3} paddingTop={5} paddingBottom={2}>
          <Grid item md={5} sm={12} xs={12}>
            <FooterAbout />
          </Grid>
          <Grid item md={3} sm={6}>
            <FooterLinks links={menu} />
          </Grid>
          <Grid item md={4} sm={6}>
            <FooterNews />
          </Grid>
        </Grid>
      </Container>
      <FooterCopyright />
    </ContainerWhiteBox>
  );
};

export default Footer;
