import { Item } from "@/components/games/DragNDropMatch/DragNDropMatchEngine/DragNDropTypes";
import api from "@/services/axios";
import { createSlice } from "@reduxjs/toolkit";

interface StateType {
  columns: { [key: string]: string };
  tasks: any;
  verify: Item;
  pontuation: number;
  tasksNumber: number;
  initGame: boolean;
  block: boolean;
  levelData: { name: string; param1: string };
}

const initialState = {
  columns: {},
  tasks: [],
  verify: {} as Item,
  pontuation: 0,
  tasksNumber: 0,
  initGame: false,
  block: false,
  levelData: {},
} as StateType;

const GameSlice = createSlice({
  name: "game",
  initialState,
  reducers: {
    getColumns(state: StateType, action) {
      state.columns = action.payload;
    },
    getTasks(state: StateType, action) {
      state.tasks = action.payload;
    },
    saveVerify(state: StateType, action) {
      state.verify = action.payload;
    },
    savePontuation(state: StateType, action) {
      state.pontuation = action.payload;
    },

    saveTasksNumber(state: StateType, action) {
      state.tasksNumber = action.payload;
    },

    loosePontuation(state: StateType) {
      const actual = state.pontuation;
      state.pontuation = actual - 1;
    },
    avanceTasks(state: StateType) {
      const actual = state.tasksNumber;
      state.tasksNumber = actual - 1;
    },
    reinitGame(state: StateType) {
      const actual = state.initGame;
      state.initGame = !actual;
    },

    saveBlock(state: StateType, action) {
      state.block = action.payload;
    },

    saveLevelData(state: StateType, action) {
      state.levelData = action.payload;
    },
  },
});

export const {
  getColumns,
  getTasks,
  saveVerify,
  savePontuation,
  reinitGame,
  loosePontuation,
  saveTasksNumber,
  avanceTasks,
  saveBlock,
  saveLevelData,
} = GameSlice.actions;

export const fetchGame = async (
  id: number,
  callback: any,
  errocallback: any
) => {
  await api
    .get(`game/${id}`)
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      if (errocallback != null) {
        errocallback(err);
      }
    });
};

export default GameSlice.reducer;
