import React from "react";
import Divider from "@mui/material/Divider";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";
import {
  Box,
  Button,
  Drawer,
  Hidden,
  Theme,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Advertisement from "@/sections/Advertisement/Advertisement";
import LogoutIcon from "@mui/icons-material/Logout";
import { useRouter } from "next/router";
import { useAuthContext } from "@/store/auth/AuthContext";
import { AppState, useSelector } from "@/store/store/Store";
import { GetIconNew } from "@/utils/GetIcon/GetIconNew";
import { MenuType } from "@/store/types/coreTypes";

const drawerWidth = 250;

interface Props {
  isMobileSidebarOpen: boolean;
  onSidebarClose: (event: React.SyntheticEvent | Event) => void;
}

const MemberMenu = ({ isMobileSidebarOpen, onSidebarClose }: Props) => {
  const { signOut } = useAuthContext();

  const menu: any[] = useSelector((state: AppState) => state.user.menu);
  const menuItems = menu.find((item: MenuType) => item.name === "Member");
  const memberMenu = menuItems?.children ?? [];

  const router = useRouter();
  const theme = useTheme();

  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));

  const getSelected = (url: any) => {
    const pathAfterDomain = router.asPath;
    if (pathAfterDomain.includes(url)) {
      return true;
    }
    return false;
  };

  const MenuItemStyled = styled(MenuItem)`
    height: 50px;
    border-radius: 10px;
    margin-top: 5px;
    color: #000;

    &:hover {
      background-color: ${theme.palette.secondary.main};
      color: ${theme.palette.white.main};
    }

    &:hover .MuiSvgIcon-root {
      color: ${theme.palette.white.main}; // Define a cor do ícone no hover
    }

    &.Mui-selected {
      background-color: ${theme.palette.secondary.light};
      color: ${theme.palette.white.main};
    }

    &.Mui-selected .MuiSvgIcon-root {
      color: ${theme.palette.white.main};
    }

    &.Mui-selected:hover {
      background-color: ${theme.palette.secondary.main};
      color: ${theme.palette.white.main};
    }

    .MuiSvgIcon-root {
      color: #000; // Define a cor do ícone no hover
    }
  `;

  const goTo = (url: any) => {
    router.push(url);
  };

  const logOut = () => {
    signOut();
    // setOpen(false);
  };

  return (
    <Drawer
      open={isMobileSidebarOpen}
      onClose={onSidebarClose}
      variant={"permanent"}
      // disableScrollLock={true}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        zIndex: mdUp ? 0 : 1,
        [`& .MuiDrawer-paper`]: {
          position: "relative",
          border: "none !important",
        },
      }}
    >
      {/* ------------------------------------------- */}
      {/* Filter Sidebar */}
      {/* ------------------------------------------- */}
      <Box
        style={{ backgroundColor: `${theme.palette.secondary.extraLight}B3` }}
        p={2}
        mt={mdUp ? 0 : 6}
        borderRadius={3}
      >
        <MenuList>
          <Typography
            variant="body1"
            color="secondary"
            fontWeight={700}
            ml={1}
            mb={1}
          >
            Área de Usuário
          </Typography>
          {memberMenu.length > 0 &&
            memberMenu.map((item: any, index: number) => {
              if (item.divider)
                return <Divider sx={{ mb: "10px", mt: "15px" }} key={index} />;

              if (!item.divider)
                return (
                  <MenuItemStyled
                    key={index}
                    selected={getSelected(item.url)}
                    onClick={() => goTo(item.url)}
                  >
                    <ListItemIcon>{GetIconNew(item.icon)}</ListItemIcon>
                    <ListItemText>{item.name}</ListItemText>
                  </MenuItemStyled>
                );
            })}
        </MenuList>
        <Divider sx={{ mb: "20px", mt: "0px" }} />
        <Box my={2}>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<LogoutIcon />}
            onClick={() => logOut()}
          >
            Sair
          </Button>
        </Box>
      </Box>

      {/* <Hidden mdDown>
        <Advertisement mt={6} />
      </Hidden> */}
    </Drawer>
  );
};

export default MemberMenu;
