import React from "react";
import {
  Modal,
  Container,
  Grid,
  CircularProgress,
  useMediaQuery,
  Theme,
  Box,
} from "@mui/material";
import LogoLoadingModal from "../../assets/PuzzleLogo.svg";
import Image from "next/image";

type FullModalType = {
  open: boolean;
  handleClose?: () => void;
};

const LoadingModal: React.FC<FullModalType> = ({ open, handleClose }) => {
  const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            padding={2}
            style={{ minHeight: "100vh" }}
          >
            <Grid item>
              <Image
                src={LogoLoadingModal}
                height={smUp ? 180 : 100}
                width={smUp ? 180 : 100}
                alt="Logo"
              />
            </Grid>

            <CircularProgress
              thickness={1}
              sx={{
                color: (theme) => theme.palette.grey[400],
                borderRadius: "50%", // Adicione borderRadius para garantir uma borda circular
              }}
              style={{
                width: smUp ? "240px" : "160px",
                height: smUp ? "240px" : "160px",
                marginTop: smUp ? "-215px" : "-135px",
              }}
            />
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default LoadingModal;
