import { MenuItem, styled, useTheme } from "@mui/material";

export const MenuItemStyled = styled(MenuItem)(({ theme }) => ({
  height: "50px",
  borderRadius: "10px",
  color: "#000",
  paddingLeft: "10px",
  paddingRight: "8px",

  "&:hover": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white.main,
  },

  "&:hover .MuiSvgIcon-root": {
    color: theme.palette.white.main,
  },

  "&.Mui-selected": {
    backgroundColor: theme.palette.primary.light,
  },

  "&.Mui-selected:hover": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white.main,
  },

  ".MuiSvgIcon-root": {
    color: "#000",
  },
}));

export const MenuSubitemStyled = styled(MenuItem)(({ theme }) => ({
  height: "40px",
  borderRadius: "10px",
  color: "#000",
  paddingLeft: "10px",
  paddingRight: "8px",

  "&:hover": {
    backgroundColor: theme.palette.neutral.main,
  },

  "&.Mui-selected": {
    backgroundColor: theme.palette.neutral.extraLight,
  },

  "&.Mui-selected:hover": {
    backgroundColor: theme.palette.neutral.extraLight,
  },

  ".MuiSvgIcon-root": {
    color: "#000",
  },
}));
